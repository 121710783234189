import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { Orders, OrdersHavingArticles } from '../api/provider';
import { OrderHavingArticles } from '../types/orderHavingArticles.types';
import { UserOrdersContextType } from '../types/userOrdersContext.types';
import { Order } from '../types/order.types';
import { AxiosRequestConfig } from 'axios';
import { OAuth2HandlerContext } from './OAuth2HandlerContextProvider';

type UserOrdersContextProviderProps = {
  children: ReactNode;
};

export const UserOrdersContext = createContext<UserOrdersContextType>({
  ordersHavingArticles: [],
  isUserOrdersContextLoading: true,
  setOrdersHavingArticles: () => {},
});

/**
 * provider for storing user orders with articles
 * @param children ReactNode where the provider will be used
 * @returns the context provider
 */
const UserOrdersContextProvider = ({
  children,
}: UserOrdersContextProviderProps) => {
  const { accounts } = useMsal();
  const [ordersHavingArticles, setOrdersHavingArticles] = useState<
    OrderHavingArticles[]
  >([]);
  const [isUserOrdersContextLoading, setIsUserOrdersContextLoading] =
    useState(true);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const ignore_fetchData = useRef(false);

  /**
   * Async function that retrieves Order[] by Uid from API
   */
  const fetchOrdersByUid = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>): Promise<Order[]> => {
      try {
        let orders: Order[];
        if (authHeaderConfig) {
          orders = await Orders.getAllByUid(
            accounts[0].localAccountId,
            authHeaderConfig
          );
        } else {
          orders = await Orders.getAllByUid(accounts[0].localAccountId);
        }
        return orders;
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
        return [];
      }
    },
    [accounts]
  );

  /**
   * Helper function to get articles for current user by orderId
   */
  const getMatchingArticlesByOrderForCurrentUser = useCallback(
    async (
      orderId: number,
      authHeaderConfig?: AxiosRequestConfig<any>
    ): Promise<OrderHavingArticles[]> => {
      let matchingArticlesByOrderId: OrderHavingArticles[] = [];
      try {
        if (authHeaderConfig) {
          matchingArticlesByOrderId =
            await OrdersHavingArticles.getAllByOrderId(
              orderId,
              authHeaderConfig
            );
        } else {
          matchingArticlesByOrderId =
            await OrdersHavingArticles.getAllByOrderId(orderId);
        }

        return matchingArticlesByOrderId;
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
        return [];
      }
    },
    []
  );

  /**
   * Async wrapper function to get OrdersHavingArticles with matching articles for current user
   */
  const getOrdersHavingArticlesForCurrentUser = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      try {
        let ordersHavingArticles: OrderHavingArticles[] = [];
        let matchingOrderedArticles: OrderHavingArticles[] = [];
        let orders: Order[];
        if (authHeaderConfig) {
          orders = await fetchOrdersByUid(authorizationRequestHeaderConfig);
        } else {
          orders = await fetchOrdersByUid();
        }

        // get all orderIds
        const orderIds: number[] = orders.map((order: Order) => order.Id);
        await Promise.all(
          orderIds.map(async (orderId) => {
            // get all objects that match the OrderId
            if (authHeaderConfig) {
              matchingOrderedArticles =
                await getMatchingArticlesByOrderForCurrentUser(
                  orderId,
                  authHeaderConfig
                );
            } else {
              matchingOrderedArticles =
                await getMatchingArticlesByOrderForCurrentUser(orderId);
            }

            matchingOrderedArticles.forEach((order: OrderHavingArticles) => {
              ordersHavingArticles.push(order);
            });
          })
        );
        // finally add all objects to it's state
        setOrdersHavingArticles(ordersHavingArticles);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
      }
    },
    [
      authorizationRequestHeaderConfig,
      fetchOrdersByUid,
      getMatchingArticlesByOrderForCurrentUser,
    ]
  );

  useEffect(() => {
    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      // fix for react with ignore to not render the useEffect twice in development mode
      // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
      if (ignore_fetchData.current === false) {
        setIsUserOrdersContextLoading(true);
        getOrdersHavingArticlesForCurrentUser(authorizationRequestHeaderConfig);
        setIsUserOrdersContextLoading(false);
      }
      return () => {
        ignore_fetchData.current = true;
      };
    }
  }, [
    accounts,
    authorizationRequestHeaderConfig,
    getOrdersHavingArticlesForCurrentUser,
  ]);

  if (isUserOrdersContextLoading) {
    //TODO: add a text value for missing data
    return <></>;
  }

  const userOrdersContextValue: UserOrdersContextType = {
    ordersHavingArticles: ordersHavingArticles,
    isUserOrdersContextLoading: true,
    setOrdersHavingArticles,
  };

  return (
    <UserOrdersContext.Provider value={userOrdersContextValue}>
      {children}
    </UserOrdersContext.Provider>
  );
};

export default UserOrdersContextProvider;
