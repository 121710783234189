import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UserContextType } from '../types/userContext.types';
import { Budget } from '../types/budget.types';
import { ShoppingCart } from '../types/shoppingCart.types';
import { useMsal } from '@azure/msal-react';
import { Budgets, ShoppingCarts } from '../api/provider';
import { OAuth2HandlerContext } from './OAuth2HandlerContextProvider';
import { AxiosRequestConfig } from 'axios';

const newDate = new Date().toISOString();
const defaultCart = {
  Id: 0,
  Created: newDate,
  CreatedBy: '',
  Modified: newDate,
  ModifiedBy: '',
  TenantId: '',
  BudgetId: 0,
};
const defaultBudget = {
  Id: 0,
  Uid: '',
  BudgetGrantedAt: newDate,
  Total: 0,
  Created: newDate,
  CreatedBy: '',
  Modified: newDate,
  ModifiedBy: '',
  TenantId: '',
};

type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContext = createContext<UserContextType>({
  budget: defaultBudget,
  cart: defaultCart,
  isUserContextLoading: true,
  setBudget: () => {},
  setCart: () => {},
});

/**
 * provider for storing user data
 * @param children ReactNode where the provider will be used
 * @returns the context provider
 */
const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { accounts } = useMsal();
  const [budget, setBudget] = useState<Budget>(defaultBudget);
  const [cart, setCart] = useState<ShoppingCart>(defaultCart);
  const [isUserContextLoading, setIsUserContextLoading] = useState(true);
  const ignore_fetchBudget = useRef(false);
  const ignore_fetchCart = useRef(false);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);

  /**
   * Async function to create user budget if there is none on init
   * from API via axios request
   */
  const createNewBudgetOnInit = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      let createdBudget;
      // TODO: change budget initialization
      const newDate = new Date().toISOString();
      const newBudget = {
        Uid: accounts[0].localAccountId,
        BudgetGrantedAt: newDate.slice(0, 10),
        Total: 200,
        Created: newDate,
        CreatedBy: accounts[0].localAccountId,
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };

      if (authHeaderConfig) {
        createdBudget = await Budgets.createOne(newBudget, authHeaderConfig);
      } else {
        createdBudget = await Budgets.createOne(newBudget);
      }
      // filter @odata.context
      //TODO: use transformResponse inside Axios provider
      delete createdBudget['@odata.context'];
      setBudget(createdBudget);
    },
    [accounts]
  );

  /**
   *  Async function to fetch user budget
   * from API via axios request
   */
  const fetchBudget = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      try {
        let budgets: Budget[] = [];
        if (authHeaderConfig) {
          budgets = await Budgets.getOneByUid(
            accounts[0].localAccountId,
            authHeaderConfig
          );
        } else {
          budgets = await Budgets.getOneByUid(accounts[0].localAccountId);
        }
        if (budgets.length > 0) {
          setBudget(budgets[0]);
        } else {
          await createNewBudgetOnInit();
        }

        setIsUserContextLoading(false);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserContextLoading(false);
      }
    },
    [accounts, createNewBudgetOnInit]
  );

  /**
   * Async function to create user cart if there is none on init
   * from API via axios request
   */
  const createNewCartOnInit = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      // create new cart
      let createdCart;
      const newCart = {
        Created: new Date().toISOString(),
        CreatedBy: accounts[0].localAccountId,
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
        BudgetId: budget.Id,
      };

      if (authHeaderConfig) {
        createdCart = await ShoppingCarts.createOne(newCart, authHeaderConfig);
      } else {
        createdCart = await ShoppingCarts.createOne(newCart);
      }
      // filter @odata.context
      //TODO: use transformResponse inside Axios provider
      delete createdCart['@odata.context'];
      setCart(createdCart);
    },
    [accounts, budget.Id]
  );

  /**
   * Async function to fetch user cart by BudgetId
   * from API via axios request
   */
  const fetchCartByBudgetId = useCallback(
    async (budgetId: number, authHeaderConfig?: AxiosRequestConfig<any>) => {
      try {
        let carts: ShoppingCart[];
        if (authHeaderConfig) {
          carts = await ShoppingCarts.getAllByBudget(
            budgetId,
            authHeaderConfig
          );
        } else {
          carts = await ShoppingCarts.getAllByBudget(budgetId);
        }

        if (carts.length > 0) {
          setCart(carts[0]);
        } else {
          await createNewCartOnInit();
        }
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserContextLoading(false);
      }
    },
    [createNewCartOnInit]
  );

  useEffect(() => {
    // fix for react with ignore to not render the useEffect twice in development mode
    // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      if (ignore_fetchBudget.current === false) {
        setIsUserContextLoading(true);
        fetchBudget(authorizationRequestHeaderConfig);
      }
      return () => {
        ignore_fetchBudget.current = true;
      };
    }
  }, [
    fetchBudget,
    authorizationRequestHeaderConfig.headers?.Authorization,
    authorizationRequestHeaderConfig,
  ]);

  useEffect(() => {
    if (budget.CreatedBy !== '') {
      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        if (ignore_fetchCart.current === false) {
          fetchCartByBudgetId(budget.Id, authorizationRequestHeaderConfig);
          setIsUserContextLoading(false);
        }
        return () => {
          ignore_fetchCart.current = true;
        };
      }
    }
  }, [
    budget.Id,
    budget.CreatedBy,
    fetchCartByBudgetId,
    authorizationRequestHeaderConfig,
  ]);

  if (isUserContextLoading) {
    //TODO: add a text value for missing data
    return <></>;
  }

  const userContextValue: UserContextType = {
    budget,
    cart,
    isUserContextLoading,
    setBudget,
    setCart,
  };

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
