import { useCallback, useContext, useEffect, useState } from 'react';
import { UserOrdersContext } from '../../../provider/UserOrdersContextProvider';
import { OrderHavingArticles } from '../../../types/orderHavingArticles.types';
import {
  DetailsListArticleCount,
  DetailsListOrderByUser,
} from '../../../types/detailsListOrdersByUser.types';
import { ShopContext } from '../../../provider/ShopContextProvider';

export const PopupGetOrders = () => {
  const { ordersHavingArticles, isUserOrdersContextLoading } =
    useContext(UserOrdersContext);
  const { articles } = useContext(ShopContext);
  const [isLoading, setisLoading] = useState<boolean>(true);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [itemsToRender, setItemsToRender] = useState<DetailsListOrderByUser[]>(
    []
  );
  //TODO: use translations library/package instead of this approach
  const columnLabels: Record<string, string> = {
    Date: 'Datum',
    Amount: 'Menge',
    Title: 'Artikel',
    Price: 'Preis',
  };

  /**
   * Function for generating DetailsList items to be rendered based on the type of items
   * @returns items to be rendered
   */
  const getItemsToRender = useCallback((items: OrderHavingArticles[]) => {
    const orderItems: DetailsListOrderByUser[] = [];
    let totalPrice = 0;

    items.forEach((item) => {
      const existingOrder = orderItems.find(
        (order) => order.OrderId === item.OrderId
      );
      const articleCount: DetailsListArticleCount = {
        ArticleId: item.ArticleId,
        count: 1,
        totalPrice: item.Price,
      };

      if (existingOrder) {
        const existingArticle = existingOrder.articles.find(
          (article) => article.ArticleId === item.ArticleId
        );
        if (existingArticle) {
          existingArticle.count++;
          existingArticle.totalPrice += item.Price;
        } else {
          existingOrder.articles.push(articleCount);
        }
      } else {
        const newOrder: DetailsListOrderByUser = {
          Created: item.Created,
          OrderId: item.OrderId,
          articles: [articleCount],
        };
        orderItems.push(newOrder);
      }

      totalPrice += item.Price;
    });

    // sort data
    const sortedOrderItems = orderItems.sort((a, b) => b.OrderId - a.OrderId);

    setTotalPrice(totalPrice);

    return sortedOrderItems;
  }, []);

  useEffect(() => {
    const items = getItemsToRender(ordersHavingArticles);
    setItemsToRender(items);
    setisLoading(false);
  }, [getItemsToRender, ordersHavingArticles]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Deine Bestellungen</div>
      </div>
      {!isUserOrdersContextLoading || isLoading ? (
        <table className="tw-table-auto">
          <thead className="tw-text-green">
            <tr>
              {Object.keys(columnLabels).map((key) => (
                <th className="tw-px-2" key={key}>
                  {columnLabels[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-text-center">
            <tr>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : itemsToRender.length > 0 ? (
        <table className="tw-table-auto">
          <thead className="tw-text-green">
            <tr>
              {Object.keys(columnLabels).map((key) => (
                <th className="tw-px-2" key={key}>
                  {columnLabels[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-text-center">
            {itemsToRender.map((item) => (
              <>
                {item.articles.map((article) => (
                  <tr>
                    <td className="tw-px-2">
                      {(() => {
                        // function for formatting ISOString to format dd.mm.yyyy
                        const isoString = item.Created;
                        const date = new Date(isoString);

                        const day = date.getDate();
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear() % 100;

                        const formattedDay = day < 10 ? `0${day}` : day;
                        const formattedMonth = month < 10 ? `0${month}` : month;
                        const formattedYear = year < 10 ? `0${year}` : year;

                        const formattedDate = `${formattedDay}.${formattedMonth}.${formattedYear}`;

                        return formattedDate;
                      })()}
                    </td>
                    <td className="tw-px-2">{article.count}</td>
                    <td className="tw-px-2">
                      {articles.find((a) => a.Id === article.ArticleId)
                        ?.Title ?? ''}
                    </td>
                    <td className="tw-px-2">
                      {article.totalPrice.toFixed(2)} €
                    </td>
                  </tr>
                ))}
              </>
            ))}
            <tr>
              <td className="tw-px-2" colSpan={2}></td>
              <td className="tw-px-2">Gesamt:</td>
              <td className="tw-px-2 tw-text-green tw-underline">
                {totalPrice.toFixed(2)} €
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <>Keine Daten gefunden.</>
      )}

      <div className="tw-flex tw-justify-center tw-pt-8"></div>
    </>
  );
};
