import {
  DetailsList,
  DetailsListLayoutMode,
  IGroup,
  IColumn,
  IIconProps,
  FontSizes,
  IconButton,
  SelectionMode,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { OrderHavingArticles } from '../../types/orderHavingArticles.types';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ShopContext } from '../../provider/ShopContextProvider';
import { OrdersPerUser } from '../../types/ordersPerUser.types';
import { buttonStyles } from '../../assets/theme/button.styles.static';
import { Budgets, Orders, OrdersHavingArticles } from '../../api/provider';
import { AllOrdersContext } from '../../provider/AllOrdersContextProvider';
import { UserOrdersContext } from '../../provider/UserOrdersContextProvider';
import { DetailsListOrderArticle } from '../../types/detailsListOrderArticle.types';
import { Budget } from '../../types/budget.types';
import { useMsal } from '@azure/msal-react';
import { OAuth2HandlerContext } from '../../provider/OAuth2HandlerContextProvider';
import { AxiosRequestConfig } from 'axios';

type DetailsListWithGroupsProps = {
  ordersPerUser: OrdersPerUser[];
};

const editIcon: IIconProps = {
  iconName: 'Edit',
  styles: {
    root: {
      fontSize: FontSizes.size16,
    },
  },
};

export const DetailsListWithGroups = memo(
  ({ ordersPerUser }: DetailsListWithGroupsProps) => {
    const { accounts: account } = useMsal();
    const { articles } = useContext(ShopContext);
    const { setOrdersPerUser } = useContext(AllOrdersContext);
    const ELEMENTS_PER_PAGE = 10;
    const { ordersHavingArticles, setOrdersHavingArticles } =
      useContext(UserOrdersContext);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [itemsToRender, setItemsToRender] = useState<
      (DetailsListOrderArticle | Partial<OrderHavingArticles>)[][]
    >([]);
    const [currentPage, setCurrentPage] = useState(0);
    const { authorizationRequestHeaderConfig } =
      useContext(OAuth2HandlerContext);
    const ignore_fetchData = useRef(false);
    /**
     * Handle page index
     * @param index page number
     */
    const onPageChange = (index: number) => {
      setCurrentPage(index);
    };

    /**
     * Helper function that generates an object with orders grouped by user
     * @param {OrdersPerUser[]} items array of items containing orders per user.
     * @returns {Object} An object with orders grouped by user.
     */
    const generateOrdersByUser = (items: OrdersPerUser[]) => {
      return items.reduce(
        (acc: { [key: string]: OrderHavingArticles[] }, cur) => {
          const { Uid, orders } = cur;
          if (!acc[Uid]) {
            acc[Uid] = [];
          }
          acc[Uid].push(...orders);
          return acc;
        },
        {}
      );
    };

    /**
     * Function for generating DetailsList display grouping on the grouping key
     * @param items items of type OrdersPerUser[]
     * @returns grouping based on items type
     */
    const getGroups = useCallback((items: OrdersPerUser[]) => {
      const ordersByUser = generateOrdersByUser(items);

      const groups: IGroup[] = Object.entries(ordersByUser).map(
        ([uid, orders], index) => ({
          key: uid,
          name: uid,
          startIndex: index * orders.length,
          count: orders.length,
          level: 0,
          isCollapsed: false,
        })
      );

      return groups;
    }, []);

    /**
     * Helper function to filter out the deleted item from the OrdersPerUser based on the deleted item id
     *  and delete OrdersPerUser if it contains no orders anymore
     * @param ordersPerUser array of items to filter
     * @param deletedItemId id of the deleted item
     * @returns {OrdersPerUser} updated array with the deleted item filtered out
     */
    const filterDeletedItemFromOrdersPerUser = (
      ordersPerUser: OrdersPerUser[],
      deletedItemId: number
    ) => {
      const updatedOrdersPerUser: OrdersPerUser[] = ordersPerUser.map(
        (orderPerUser) => {
          const updatedOrders = orderPerUser.orders.filter(
            (order) => order.Id !== deletedItemId
          );

          return {
            ...orderPerUser,
            orders: updatedOrders,
          };
        }
      );

      // filter OrdersPerUser objects that have no orders anymore
      const filteredOrdersPerUser = updatedOrdersPerUser.filter(
        (orderPerUser) => orderPerUser.orders.length > 0
      );
      return filteredOrdersPerUser;
    };

    /**
     * Helper function that filters the orders having articles by specified id
     * @param ordersHavingArticles array of orders having articles
     * @param {number} id id to filter the orders by
     * @returns {OrderHavingArticles[]} filtered array of orders having articles.
     */
    const filterOrdersHavingArticlesById = (
      ordersHavingArticles: OrderHavingArticles[],
      id: number
    ) => {
      return ordersHavingArticles.filter((item) => item.Id === id);
    };

    /**
     * Helper function that calculates the updated budget based on the original budget and the price
     * @param userBudget original budget object
     * @param price price to deduct from the budget
     * @returns {Budget} updated budget object
     */
    const calculateUpdatedBudget = useCallback(
      (userBudget: Budget, price: number) => {
        const partialBudget: Partial<Budget> = {
          Total: userBudget.Total - price,
        };
        return {
          ...userBudget,
          ...partialBudget,
          Modified: new Date().toISOString(),
          ModifiedBy: account[0].localAccountId,
          TenantId: `${process.env.REACT_APP_TENANT_ID}`,
        };
      },
      [account]
    );

    /**
     * Async function that updates OrdersPer User by OrdersHavingArticles id
     */
    const updaterOrdersPerUserByDeletedItemId = useCallback(
      (ordersHavingArticlesId: number) => {
        const updatedOrdersPerUser: OrdersPerUser[] =
          filterDeletedItemFromOrdersPerUser(
            ordersPerUser,
            ordersHavingArticlesId
          );
        setOrdersPerUser(updatedOrdersPerUser);
      },
      [ordersPerUser, setOrdersPerUser]
    );

    /**
     * Async function to delete OrdersHavingArticles obj by userId and authHeaderConfig
     * @param ordersHavingArticlesId id to delete item
     * @param authHeaderConfig oauth header config
     */
    const deleteOrdersHavingArticlesById = async (
      ordersHavingArticlesId: number,
      authHeaderConfig?: AxiosRequestConfig<any>
    ) => {
      if (authHeaderConfig) {
        await OrdersHavingArticles.deleteOne(
          ordersHavingArticlesId,
          authHeaderConfig
        );
      } else {
        await OrdersHavingArticles.deleteOne(ordersHavingArticlesId);
      }
    };

    /**
     * Async function to update ordersHaving Articles
     * @param ordersHavingArticlesId item id that will be filtered out
     */
    const updateOrdersHavingArticlesByDeletedItemId = useCallback(
      async (ordersHavingArticlesId: number) => {
        const updatedOrdersHavingArticles: OrderHavingArticles[] =
          await filterOrdersHavingArticlesById(
            ordersHavingArticles,
            ordersHavingArticlesId
          );

        setOrdersHavingArticles(updatedOrdersHavingArticles);
      },
      [ordersHavingArticles, setOrdersHavingArticles]
    );

    /**
     * Async function to update the user's budget
     * @param Price that an item costs
     * @param Uid user id that the item belongs to
     * @param authHeaderConfig oauth header config
     */
    const updateBudget = useCallback(
      async (
        Price: number,
        Uid: string,
        authHeaderConfig?: AxiosRequestConfig<any>
      ) => {
        let budgets: Budget[] = [];
        if (authHeaderConfig) {
          budgets = await Budgets.getOneByUid(Uid, authHeaderConfig);
        } else {
          budgets = await Budgets.getOneByUid(Uid);
        }

        const userBudget = budgets[0];
        const updatedBudget = calculateUpdatedBudget(userBudget, Price);

        if (authHeaderConfig) {
          await Budgets.updateOne(
            userBudget.Id,
            updatedBudget,
            authHeaderConfig
          );
        } else {
          await Budgets.updateOne(userBudget.Id, updatedBudget);
        }
      },
      [calculateUpdatedBudget]
    );

    const getItemsInOrderByOrderId = async (
      OrderId: number,
      authHeaderConfig?: AxiosRequestConfig<any>
    ): Promise<OrderHavingArticles[]> => {
      let itemsInOrder: OrderHavingArticles[] = [];
      if (authHeaderConfig) {
        itemsInOrder = await OrdersHavingArticles.getAllByOrderId(
          OrderId,
          authHeaderConfig
        );
      } else {
        itemsInOrder = await OrdersHavingArticles.getAllByOrderId(OrderId);
      }
      return itemsInOrder;
    };

    /**
     * Function for generating DetailsList display columns based on the type of items
     * @returns grouping based on items type
     */
    const getColumns = useCallback(
      (authHeaderConfig?: AxiosRequestConfig<any>) => {
        // inner function because getColumns does not accept async methods
        /**
         * Function for deleting orders and setting the budget after Article has been deleted
         * @param item column item that will be deleted from list
         */
        const processOrderedArticle = async (
          item: DetailsListOrderArticle,
          authHeaderConfig?: AxiosRequestConfig<any>
        ) => {
          const { OrderId, OrdersHavingArticlesId, Uid, Price } = item; // destruct obj
          try {
            if (authHeaderConfig) {
              await deleteOrdersHavingArticlesById(
                OrdersHavingArticlesId,
                authHeaderConfig
              );
            } else {
              await deleteOrdersHavingArticlesById(OrdersHavingArticlesId);
            }

            await updaterOrdersPerUserByDeletedItemId(OrdersHavingArticlesId);

            await updateOrdersHavingArticlesByDeletedItemId(
              OrdersHavingArticlesId
            );

            if (Price !== null) {
              let itemsInOrder: OrderHavingArticles[];
              if (authHeaderConfig) {
                await updateBudget(Price, Uid, authHeaderConfig);
                itemsInOrder = await getItemsInOrderByOrderId(
                  OrderId,
                  authHeaderConfig
                );
              } else {
                await updateBudget(Price, Uid);
                itemsInOrder = await getItemsInOrderByOrderId(OrderId);
              }

              if (itemsInOrder.length === 0) {
                if (authHeaderConfig) {
                  await Orders.deleteOne(OrderId, authHeaderConfig);
                } else {
                  await Orders.deleteOne(OrderId);
                }
              }
            }
          } catch (error) {
            console.log('Error message:' + error);
          }
        };

        return [
          {
            key: 'Uid',
            name: 'Nutzer',
            fieldName: '',
            minWidth: 100,
            maxWidth: 300,
          },
          {
            key: 'OrderNumber',
            name: 'Bestellnummer',
            fieldName: 'OrderNumber',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            key: 'ArticleId',
            name: 'Artikel',
            fieldName: 'ArticleId',
            minWidth: 100,
            maxWidth: 300,
            onRender: (item: DetailsListOrderArticle) => {
              const articleName = articles.filter(
                (article) => article.Id === item.ArticleId
              )[0].Title;
              return <span>{articleName}</span>;
            },
          },
          {
            key: 'Price',
            name: 'Preis',
            fieldName: 'Price',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: DetailsListOrderArticle) => {
              if (item.Price == null) {
                return <span>Kein Preis angegeben.</span>;
              }
              return <span>{item.Price?.toFixed(2)} €</span>;
            },
          },
          {
            key: '',
            name: 'Bestellung abarbeiten',
            fieldName: '',
            minWidth: 100,
            onRender: (item: DetailsListOrderArticle) => {
              return (
                <>
                  <IconButton
                    className="tw-text-grey"
                    iconProps={editIcon}
                    styles={buttonStyles}
                    title="Editieren"
                    ariaLabel="Editieren"
                    onClick={() => {
                      processOrderedArticle(item, authHeaderConfig);
                    }}
                  />
                </>
              );
            },
          },
        ];
      },
      [
        articles,
        updaterOrdersPerUserByDeletedItemId,
        updateOrdersHavingArticlesByDeletedItemId,
        updateBudget,
      ]
    );

    /**
     * Helper function for showing the page elements displayed by the ui
     * @param array the array that is used
     * @param pageSize the page size that shows the maxiumum elements
     * @returns array of grouped elements displayed per page in the pagination
     */
    const paginateArray = (array: any[], pageSize: number) => {
      const paginatedArray = [];
      const pageCount = Math.ceil(array.length / pageSize);

      for (let i = 0; i < pageCount; i++) {
        const startIndex = i * pageSize;
        const endIndex = startIndex + pageSize;
        const pageItems = array.slice(startIndex, endIndex);
        paginatedArray.push(pageItems);
      }

      return paginatedArray;
    };

    /**
     * Function for generating DetailsList items to be rendered
     * @returns items to be rendered
     */
    const getItemsToRender = useCallback((items: OrdersPerUser[]) => {
      const ordersByUser = generateOrdersByUser(items);

      const itemsByUser: DetailsListOrderArticle[] = Object.entries(
        ordersByUser
      ).flatMap(([uid, orders]) => {
        return orders.map((order) => {
          const { OrderNumber, Id, OrderId, ArticleId, Price } = order;
          return {
            Uid: uid,
            OrderNumber: OrderNumber,
            OrderId: OrderId,
            OrdersHavingArticlesId: Id,
            ArticleId: ArticleId,
            Price: Price,
          };
        });
      });

      return paginateArray(itemsByUser, ELEMENTS_PER_PAGE);
    }, []);

    useEffect(() => {
      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        // fix for react with ignore to not render the useEffect twice in development mode
        // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
        if (ignore_fetchData.current === false) {
          if (ordersPerUser) {
            const columnsToRender = getColumns(
              authorizationRequestHeaderConfig
            );
            const groupsToRender = getGroups(ordersPerUser);
            const itemsToRender = getItemsToRender(ordersPerUser);

            setColumns(columnsToRender);
            setGroups(groupsToRender);
            setItemsToRender(itemsToRender);
          }
        }
      }
    }, [
      ordersPerUser,
      getColumns,
      getGroups,
      getItemsToRender,
      authorizationRequestHeaderConfig,
    ]);

    return (
      <>
        {itemsToRender.length > 0 && (
          <>
            <DetailsList
              items={itemsToRender[currentPage]}
              columns={columns}
              groups={groups}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
            <Pagination
              selectedPageIndex={currentPage}
              pageCount={itemsToRender.length}
              itemsPerPage={ELEMENTS_PER_PAGE}
              totalItemCount={itemsToRender.length * itemsToRender[0].length}
              format={'buttons'}
              previousPageAriaLabel={'Vorherige Seite page'}
              nextPageAriaLabel={'Nächste Seite'}
              firstPageAriaLabel={'Erste Seite'}
              lastPageAriaLabel={'Letzte Seite'}
              pageAriaLabel={'Seite'}
              selectedAriaLabel={'ausgewählte'}
              onPageChange={onPageChange}
            />
          </>
        )}
      </>
    );
  }
);
