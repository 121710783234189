import { DefaultButton, IDropdownOption } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { dropdownStyles } from '../../../assets/theme/dropdown.styles.static';
import { textFieldArticleStyles } from '../../../assets/theme/textfield.article.styles.static';
import { Article } from '../../../types/article.types';
import { useForm } from 'react-hook-form';
import { EditArticleForm } from '../../../types/editArticleForm.types';
import { ControlledDropdown } from '../../../shared/controlledDropdown/ControlledDropdown';
import { ControlledTextfield } from '../../../shared/controlledTextfield/ControlledTextfield';
import { Articles } from '../../../api/provider';
import { useMsal } from '@azure/msal-react';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupEditArticleProps = {
  hide: () => void;
  articleId: number;
};

export const PopupEditArticle = ({
  hide,
  articleId,
}: PopupEditArticleProps) => {
  const { accounts: account } = useMsal();
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const { articles, categories, setArticles } = useContext(ShopContext);
  const selectedArticle = articles.find((article) => article.Id === articleId);

  if (selectedArticle === null || selectedArticle === undefined) {
    throw new Error('A problem with the item id occured');
  }

  const categoryName = categories.find(
    (category) => category.Id === selectedArticle.CategoryId
  )?.Name;

  const [selectedCategory] = useState(selectedArticle.CategoryId);
  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, isValid, errors },
  } = useForm<EditArticleForm>({
    defaultValues: {
      CategoryId: selectedArticle.toString() ?? '',
      Title:
        selectedArticle.Title !== null ? selectedArticle.Title.toString() : '',
      Price:
        selectedArticle.Price !== null ? selectedArticle.Price.toString() : '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const categoryDropdownOptions: IDropdownOption[] = categories.map(
    ({ Id, Name }) => ({
      key: Id,
      text: Name,
    })
  );

  const onSave = () => {
    handleSubmit(async (data: EditArticleForm) => {
      const partialArticle: Partial<Article> = {};

      // build patched object when field is dirty
      if (dirtyFields.Title?.valueOf() === true) {
        partialArticle.Title = data.Title;
      }
      if (dirtyFields.CategoryId?.valueOf() === true) {
        partialArticle.CategoryId = +data.CategoryId;
      }
      if (dirtyFields.Price?.valueOf() === true) {
        partialArticle.Price = +data.Price;
      }

      // add obj together
      const updateArticle: Article = {
        ...selectedArticle,
        ...partialArticle,
        Modified: new Date().toISOString(),
        ModifiedBy: account[0].localAccountId,
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };

      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        //TODO: handle request properly with timeout and notification
        await Articles.updateOne(
          selectedArticle.Id,
          updateArticle,
          authorizationRequestHeaderConfig
        )
          .then(() => {
            // update the state of the articles array using the setter function of it's context
            const updatedArticles: Article[] = articles.map((article) =>
              article.Id === updateArticle.Id ? updateArticle : article
            );

            setArticles(updatedArticles);
          })
          .catch((error) => {
            //TODO: add proper error handling
            console.log('Error message:' + error);
          });
      } else {
        await Articles.updateOne(selectedArticle.Id, updateArticle)
          .then(() => {
            // update the state of the articles array using the setter function of it's context
            const updatedArticles: Article[] = articles.map((article) =>
              article.Id === updateArticle.Id ? updateArticle : article
            );

            setArticles(updatedArticles);
          })
          .catch((error) => {
            //TODO: add proper error handling
            console.log('Error message:' + error);
          });
      }
    })();

    // close popup on save
    hide();
  };

  useEffect(() => {}, [isDirty, dirtyFields, isValid, errors, authorizationRequestHeaderConfig]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Artikel editieren</div>
        <div>
          <div className="tw-flex tw-grow">
            <div>
              <ControlledDropdown
                options={categoryDropdownOptions}
                styles={dropdownStyles}
                selectedKey={selectedCategory}
                control={control}
                name="CategoryId"
                placeholder={categoryName ?? 'Keine Kategorie gewählt'}
              />
            </div>
          </div>
          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            name="Title"
          />
          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            type="number"
            rules={{
              min: {
                message: 'Bitte minimalen Eintrag mit mindestens 0€ beziffern',
                value: 0,
              },
              valueAsNumber: true,
            }}
            name="Price"
          />
        </div>
      </div>

      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Abbrechen"
          text="Abbrechen"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          disabled={!isValid || !isDirty}
          ariaLabel="Speichern"
          text="Speichern"
          onClick={handleSubmit(onSave)}
        />
      </div>
    </>
  );
};
