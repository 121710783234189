import { Article } from "../../types/article.types";
import { Category } from "../../types/category.types";
import {
  DefaultButton,
  FontSizes,
  IIconProps,
  IconButton,
  TextField,
} from "@fluentui/react";
import { buttonStyles } from "../../assets/theme/button.styles.static";
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useState } from "react";
import { ShopContext } from "../../provider/ShopContextProvider";
import { PopupContainer } from "../../components/popups/popupContainer/PopupContainer";
import { PopupDeleteArticle } from "../../components/popups/popupDeleteArticle/PopupDeleteArticle";
import { PopupEditArticle } from "../../components/popups/popupEditArticle/PopupEditArticle";
import { ArticleCategoryMap } from "../../types/articleCategoryMap";
import { PopupEditCategory } from "../../components/popups/popupEditCategory/popupEditCategory";
import { PopupDeleteCategory } from "../../components/popups/popupDeleteCategory/PopupDeleteCategory";
import { textFieldStyles } from "../../assets/theme/textfield.styles.static";
import { PopupAddArticle } from "../../components/popups/popupAddArticle/PopupAddArticle";
import { PopupAddCategory } from "../../components/popups/popupAddCategory/PopupAddCategory";
import { CustomPagination } from "../customPagination/CustomPagination";

type TableWithPopupsProps = {
  data: Article[] | Category[];
  tableType: string;
  isDataLoading: boolean;
  articleCategoryMap?: ArticleCategoryMap[];
};

const binIcon: IIconProps = {
  iconName: "Delete",
  styles: {
    root: {
      fontSize: FontSizes.size16,
    },
  },
};

const editIcon: IIconProps = {
  iconName: "Edit",
  styles: {
    root: {
      fontSize: FontSizes.size16,
    },
  },
};

const TableWithPopups = ({
  data,
  tableType,
  isDataLoading,
  articleCategoryMap,
}: TableWithPopupsProps) => {
  const { isShopContextLoading } = useContext(ShopContext);
  const [
    isPopupEditVisible,
    { setTrue: showEditPopup, setFalse: hideEditPopup },
  ] = useBoolean(false);
  const [
    isPopupDeleteVisible,
    { setTrue: showDeletePopup, setFalse: hideDeletePopup },
  ] = useBoolean(false);
  const [isPopupAddVisible, { setTrue: showAddPopup, setFalse: hideAddPopup }] =
    useBoolean(false);
  const [currIndex, setCurrIndex] = useState<number>();

  const [itemId, setItemId] = useState<number>();

  // pagination variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // calculate start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // slice the data array to get the current page's data
  const filteredData = data.slice(startIndex, endIndex);

  let columnLabels: Record<string, string>;
  const headerKeys: string[] = [];
  // check which labels to use based on the type
  if (tableType === "Artikel") {
    //TODO: use translations library/package instead of this approach
    columnLabels = {
      Id: "Id",
      Title: tableType,
      Price: "Preis",
      CategoryId: "Kategorie",
    };
  } else {
    columnLabels = {
      Id: "Id",
      Name: tableType,
    };
  }

  Object.keys(columnLabels).forEach((key) => {
    headerKeys.push(key);
  });

  return (
    <>
      <div className="tw-flex tw-flex-row tw-w-full tw-my-2">
        <div>
          <TextField styles={textFieldStyles} disabled />
        </div>

        <div className="tw-flex tw-grow tw-justify-end tw-items-center">
          <DefaultButton
            className="tw-text-white tw-bg-grey-dark tw-rounded"
            styles={buttonStyles}
            ariaLabel={`${tableType} anlegen`}
            text={`${tableType} anlegen`}
            onClick={() => showAddPopup()}
          />
          {isPopupAddVisible && tableType === "Artikel" && (
            <PopupContainer
              child={<PopupAddArticle hide={hideAddPopup} />}
              hide={hideAddPopup}
            />
          )}
          {isPopupAddVisible && tableType === "Kategorie" && (
            <PopupContainer
              child={<PopupAddCategory hide={hideAddPopup} />}
              hide={hideAddPopup}
            />
          )}
        </div>
      </div>
      <table className="tw-table-auto tw-text-grey tw-text-lg tw-w-full">
        <thead className="tw-text-green">
          <tr>
            {Object.keys(columnLabels).map((key) => (
              <th className="tw-px-2" key={key}>
                {columnLabels[key]}
              </th>
            ))}
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="tw-text-center">
          {isShopContextLoading && isDataLoading ? (
            <tr>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
              <td>
                <div className="skeleton skeleton-text"></div>
              </td>
            </tr>
          ) : filteredData.length ? (
            filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.Id}</td>
                {headerKeys.slice(1).map((header) => (
                  <td key={header}>
                    {header === "Price"
                      ? `${item[header as keyof typeof item]} €`
                      : item[header as keyof typeof item] &&
                        header === "CategoryId"
                      ? articleCategoryMap!.find(
                          (map) => map.article.Id === item.Id
                        )?.categoryName
                      : item[header as keyof typeof item]}
                  </td>
                ))}
                <td>
                  <IconButton
                    className="tw-text-grey"
                    iconProps={editIcon}
                    styles={buttonStyles}
                    title="Editieren"
                    ariaLabel="Editieren"
                    onClick={() => {
                      setCurrIndex(index + pageSize * (currentPage - 1));
                      setItemId(item.Id);
                      showEditPopup();
                    }}
                  />
                </td>
                <td>
                  <IconButton
                    className="tw-text-grey"
                    iconProps={binIcon}
                    styles={buttonStyles}
                    title="Löschen"
                    ariaLabel="Löschen"
                    onClick={() => {
                      setCurrIndex(index + pageSize * (currentPage - 1));
                      setItemId(item.Id);
                      showDeletePopup();
                    }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>Keine Artikel gefunden.</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="tw-flex tw-justify-center">
        <CustomPagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={pageSize}
          onPageChange={(newPage: number) => setCurrentPage(newPage)}
        />
      </div>

      {isPopupEditVisible &&
        currIndex !== undefined &&
        tableType === "Artikel" &&
        articleCategoryMap !== undefined && (
          <PopupContainer
            child={
              <PopupEditArticle
                articleId={articleCategoryMap[currIndex].article.Id}
                hide={hideEditPopup}
              />
            }
            hide={hideEditPopup}
          />
        )}

      {isPopupEditVisible &&
        tableType === "Kategorie" &&
        itemId !== undefined && (
          <PopupContainer
            child={
              <PopupEditCategory hide={hideEditPopup} categoryId={itemId} />
            }
            hide={hideEditPopup}
          />
        )}

      {isPopupDeleteVisible &&
        currIndex !== undefined &&
        tableType === "Artikel" &&
        articleCategoryMap !== undefined && (
          <>
            <PopupContainer
              child={
                <PopupDeleteArticle
                  articleId={articleCategoryMap[currIndex].article.Id}
                  hide={hideDeletePopup}
                />
              }
              hide={hideDeletePopup}
            />
          </>
        )}

      {isPopupDeleteVisible &&
        tableType === "Kategorie" &&
        itemId !== undefined && (
          <>
            <PopupContainer
              child={
                <PopupDeleteCategory
                  categoryId={itemId}
                  hide={hideDeletePopup}
                />
              }
              hide={hideDeletePopup}
            />
          </>
        )}
    </>
  );
};

export default TableWithPopups;
