import React, { ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { PERMISSIONS } from "../../permission-maps";

interface PermissionGateProps {
  children: ReactNode;
  scopes?: string[];
}

const hasPermission = ({
  permissions,
  scopes,
}: {
  permissions: string[];
  scopes: string[];
}) => {
  const scopeMap: { [key: string]: boolean } = {};
  scopes.forEach((scope) => {
    scopeMap[scope] = true;
  });

  return permissions.some((permission) => scopeMap[permission]);
};

export const PermissionGate: React.FC<PermissionGateProps> = ({
  children,
  scopes = [],
}) => {
  let permissionGranted = false;
  const { accounts } = useMsal();
  let role: string = "";

  //TODO: add proper error handling when no role is assigned in the token
  if (
    accounts[0]?.idTokenClaims &&
    accounts[0]?.idTokenClaims["roles"] &&
    accounts[0].idTokenClaims["roles"].length > 0
  ) {
    role = accounts[0]?.idTokenClaims["roles"]?.[0];
  }

  if (role) {
    const permissions = PERMISSIONS[role];

    permissionGranted = hasPermission({ permissions, scopes });
  }

  if (!permissionGranted) return <></>;
  return <>{children}</>;
};
