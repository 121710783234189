import { useMsal } from '@azure/msal-react';
import {
  DefaultButton,
  FontSizes,
  IconButton,
  IIconProps,
} from '@fluentui/react';
import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import {
  Orders,
  OrdersHavingArticles,
  ShoppingCartsHavingArticles,
} from '../../../api/provider';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { ShoppingCartHavingArticles } from '../../../types/shoppingCartHavingArticles';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { UserContext } from '../../../provider/UserContextProvider';
import { PopupGetCartItem } from '../../../types/popupGetCartItem.types';
import { Article } from '../../../types/article.types';
import { AxiosRequestConfig } from 'axios';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';
import { OrderHavingArticles } from '../../../types/orderHavingArticles.types';
import { Order } from '../../../types/order.types';

const binIcon: IIconProps = {
  iconName: 'Delete',
  styles: {
    root: {
      fontSize: FontSizes.size16,
    },
  },
};

type PopupGetCartProps = {
  hide: () => void;
};

export const PopupGetCart = ({ hide }: PopupGetCartProps) => {
  const { accounts } = useMsal();
  const [cartItems, setCartListItems] = useState<PopupGetCartItem[]>([]);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const [shoppingCartHavingArticles, setShoppingCartHavingArticles] = useState<
    ShoppingCartHavingArticles[]
  >([]);
  const { isShopContextLoading, articles } = useContext(ShopContext);
  const { cart } = useContext(UserContext);
  const ignore_fetchData = useRef(false);

  /**
   *  Function for retirieving the ui display object PopupGetCartItem[]
   * @param articlesCountMap a map that contains <count, articles>
   * @returns the actual shopping cart
   */
  const getCartItems = useCallback(
    async (
      articlesCountMap: Map<number, number>
    ): Promise<PopupGetCartItem[]> => {
      const cart: PopupGetCartItem[] = [];
      // retrieve articles by matching id
      for (const [articleId, count] of articlesCountMap.entries()) {
        const article = articles.find(
          (article) => article.Id === articleId
        ) as Article;
        cart.push({ count, article });
      }

      return cart;
    },
    [articles]
  );

  /**
   * Async function to retrieve ShoppingCartsHavingArticles
   */
  const fetchShoppingCartsHavingArticlesByCartId = useCallback(
    async (
      shoppingCartId: number,
      authHeaderConfig?: AxiosRequestConfig<any>
    ): Promise<ShoppingCartHavingArticles[]> => {
      try {
        let shoppingCartHavingArticles: ShoppingCartHavingArticles[];
        if (authHeaderConfig) {
          shoppingCartHavingArticles =
            await ShoppingCartsHavingArticles.getAllByShoppingCart(
              shoppingCartId,
              authHeaderConfig
            );
        } else {
          shoppingCartHavingArticles =
            await ShoppingCartsHavingArticles.getAllByShoppingCart(
              shoppingCartId
            );
        }
        setShoppingCartHavingArticles(shoppingCartHavingArticles);
        return shoppingCartHavingArticles;
      } catch (error) {
        //TODO: add proper error handling
        console.log('Error message:' + error);
        return [];
      }
    },
    []
  );

  //TODO: refactor method -> split in seperate methods
  const fetchData = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>): Promise<void> => {
      let shoppingCartHavingArticles;
      try {
        // retrieve all articles in cart
        if (authHeaderConfig) {
          shoppingCartHavingArticles =
            await fetchShoppingCartsHavingArticlesByCartId(
              cart.Id,
              authHeaderConfig
            );
        } else {
          shoppingCartHavingArticles =
            await fetchShoppingCartsHavingArticlesByCartId(cart.Id);
        }

        // to request articles that are only stored in cart
        const articlesCountMap: Map<number, number> = await getArticlesCountMap(
          shoppingCartHavingArticles
        );

        // return cart items as array <count, Article>
        const cartListItems = await getCartItems(articlesCountMap);
        setCartListItems(cartListItems);
      } catch (error) {
        //TODO: add proper error handling
        console.log('Error message:' + error);
      }
    },
    [cart.Id, getCartItems, fetchShoppingCartsHavingArticlesByCartId]
  );

  /**
   * Async function to create a new order on submit
   * @param date actual date when button has been submitted
   * @returns
   */
  const createNewOrderOnSubmit = async (date: string): Promise<Order> => {
    let newOrder;
    const newPartialOrder: Partial<Order> = {
      Uid: accounts[0].localAccountId,
      Created: date,
      CreatedBy: accounts[0].localAccountId,
      Modified: date,
      ModifiedBy: accounts[0].localAccountId,
      TenantId: `${process.env.REACT_APP_TENANT_ID}`,
    };

    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      newOrder = await Orders.createOne(
        newPartialOrder,
        authorizationRequestHeaderConfig
      );
    } else {
      newOrder = await Orders.createOne(newPartialOrder);
    }

    return newOrder;
  };

  /**
   * Async function to create n:m orders having Articles Objects
   * @param order that contains Articles
   * @returns number of ordered items as array
   */

  const createOrdersHavingArticlesOnSubmit = async (
    order: Order
  ): Promise<number[]> => {
    // create new relation between orders and article for each item in ShoppingCart
    //TODO: get a real scheme for OrderNumber
    const orderItems = await Promise.all(
      shoppingCartHavingArticles.map(async (article) => {
        const price = articles.filter((a) => a.Id === article.ArticleId)[0]
          .Price; // get price from context

        //TODO: get a real scheme for OrderNumber
        const partialOrderHavingArticles: Partial<OrderHavingArticles> = {
          ArticleId: article.ArticleId,
          OrderId: order.Id,
          OrderNumber: order.Id,
          Price: price,
          Created: new Date().toISOString(),
          CreatedBy: accounts[0].localAccountId,
          TenantId: `${process.env.REACT_APP_TENANT_ID}`,
        };

        if (
          authorizationRequestHeaderConfig.headers?.Authorization !== undefined
        ) {
          await OrdersHavingArticles.createOne(
            partialOrderHavingArticles,
            authorizationRequestHeaderConfig
          );
        } else {
          await OrdersHavingArticles.createOne(partialOrderHavingArticles);
        }

        return article.Id;
      })
    );

    return orderItems;
  };

  /**
   * Async function to remove articles from cart in context and in database
   * @param order that contains Articles
   */
  const removeShoppingCartsHavingArticlesFromCart = async (
    orderItems: number[]
  ): Promise<void> => {
    // delete backend wise
    await Promise.all(
      orderItems.map(async (id) => {
        if (
          authorizationRequestHeaderConfig.headers?.Authorization !== undefined
        ) {
          await ShoppingCartsHavingArticles.deleteOne(
            id,
            authorizationRequestHeaderConfig
          );
        } else {
          await ShoppingCartsHavingArticles.deleteOne(id);
        }
      })
    );
  };

  /**
   * Async function to submit order
   */
  const submitOrder = async (): Promise<void> => {
    // create new date
    const newDate = new Date().toISOString();

    let newOrder = await createNewOrderOnSubmit(newDate);
    let orderItems = await createOrdersHavingArticlesOnSubmit(newOrder);

    // empty cart for item id that will be deleted next on backend side
    const storeCartItemsToDelete = orderItems.filter((id) =>
      shoppingCartHavingArticles.some((a) => a.Id === id)
    );

    // delete item on backend side
    await removeShoppingCartsHavingArticlesFromCart(storeCartItemsToDelete);

    // close popup
    hide();
  };

  useEffect(() => {
    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      // fix for react with ignore to not render the useEffect twice in development mode
      // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
      if (ignore_fetchData.current === false) {
        fetchData(authorizationRequestHeaderConfig);
      }
      return () => {
        ignore_fetchData.current = true;
      };
    }
  }, [
    fetchData,
    authorizationRequestHeaderConfig.headers?.Authorization,
    authorizationRequestHeaderConfig,
  ]);

  // call a second time to make cartItems state usable
  useEffect(() => {}, [cartItems]);

  /**
   * Helper function to generate a map which stores the count of each article item that has been added to cart
   * @param articlesInCart map that helds for each article its count and Id
   * @returns a map with counted articles
   */
  const getArticlesCountMap = async (
    articlesInCart: ShoppingCartHavingArticles[]
  ): Promise<Map<number, number>> => {
    // store article counts <count, ArticleId>
    const articlesCount: Map<number, number> = new Map<number, number>();

    articlesInCart.forEach((cartItem) => {
      const articleId = cartItem.ArticleId;

      // get current count for article from map, or 0 if it doesn't exist
      const count = articlesCount.get(articleId) ?? 0;
      articlesCount.set(articleId, count + 1);
    });

    return articlesCount;
  };

  /**
   * Function for deleting an item from the ShoppingCart and updating display UI map
   * @param id id of item to delete
   */
  const remainingShoppingCarts = async (id: number) => {
    const latestCartWithArticleId = shoppingCartHavingArticles
      .filter((item) => item.ArticleId === id) // filter for Articles that have Id
      .sort((a, b) => b.Id - a.Id)[0].Id; // sort to highest ShoppingCartHavingArticles.Id

    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      // delete latest ShoppingCartHavingArticles entry
      await ShoppingCartsHavingArticles.deleteOne(
        latestCartWithArticleId,
        authorizationRequestHeaderConfig
      );
    } else {
      await ShoppingCartsHavingArticles.deleteOne(latestCartWithArticleId);
    }

    // update shoppingCartHavingArticles state
    setShoppingCartHavingArticles((prevCart) =>
      prevCart.filter((item) => item.Id !== latestCartWithArticleId)
    );

    // update cart state
    const articlesCountMap = await getArticlesCountMap(
      shoppingCartHavingArticles.filter(
        (item) => item.Id !== latestCartWithArticleId
      )
    );
    const cartItems = await getCartItems(articlesCountMap);
    setCartListItems(cartItems);
  };

  return (
    <>
      {!cartItems.length ? (
        <div>Es befinden sich keine Artikel im Warenkorb.</div>
      ) : (
        <table className="tw-table-auto">
          <thead className="tw-text-green">
            <tr>
              <th className="tw-px-2">Anzahl</th>
              <th className="tw-px-2">Artikel</th>
              <th className="tw-px-2">Preis</th>
              <th className="tw-px-2">Gesamtpreis</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="tw-text-center">
            {isShopContextLoading ? (
              <>
                <tr>
                  <td>
                    <div className="skeleton skeleton-text" />
                  </td>
                  <td className="tw-px-2">
                    <div className="skeleton skeleton-text" />
                  </td>
                  <td className="tw-px-2">
                    <div className="skeleton skeleton-text" />
                  </td>
                  <td className="tw-px-2">
                    <div className="skeleton skeleton-text" />
                  </td>
                  <td>
                    <div className="skeleton skeleton-text" />
                  </td>
                </tr>
              </>
            ) : (
              cartItems.map((item) => (
                <tr key={item.article.Id}>
                  <td>{item.count}</td>
                  <td className="tw-px-2">{item.article.Title}</td>
                  <td className="tw-px-2">{item.article.Price} €</td>
                  <td className="tw-px-2">
                    {item.article.Price * item.count} €
                  </td>
                  <td>
                    <IconButton
                      className="tw-text-grey"
                      iconProps={binIcon}
                      styles={buttonStyles}
                      title="Löschen"
                      ariaLabel="Löschen"
                      onClick={async () =>
                        await remainingShoppingCarts(item.article.Id)
                      }
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
      <div className="tw-flex tw-justify-end tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded"
          styles={buttonStyles}
          ariaLabel="Bestellen"
          text="Bestellen"
          disabled={!cartItems.length}
          onClick={submitOrder}
        />
      </div>
    </>
  );
};
