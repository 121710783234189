import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Article } from '../types/article.types';
import { Category } from '../types/category.types';
import { Budget } from '../types/budget.types';
import { ShoppingCart } from '../types/shoppingCart.types';
import { ShoppingCartHavingArticles } from '../types/shoppingCartHavingArticles';
import { Order } from '../types/order.types';
import { OrderHavingArticles } from '../types/orderHavingArticles.types';
import { axiosDefaultConfig } from '../configs/axiosDefaultConfig';

const instance = axios.create(axiosDefaultConfig);

const responseBody = (response: AxiosResponse) =>
  response.data.value !== undefined ? response.data.value : response.data;

// general CRUD-requests definitions
const requests = {
  get: (url: string, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return instance.get(url, config).then(responseBody);
    }
    return instance.get(url).then(responseBody);
  },
  post: (url: string, body: {}, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return instance.post(url, body, config).then(responseBody);
    }
    return instance.post(url, body).then(responseBody);
  },
  patch: (url: string, body: {}, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return instance.patch(url, body, config).then(responseBody);
    }
    return instance.patch(url, body).then(responseBody);
  },
  delete: (url: string, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return instance.delete(url, config).then(responseBody);
    }
    return instance.delete(url).then(responseBody);
  },
};

// specific requests for all objects
export const Articles = {
  getAll: (config?: AxiosRequestConfig<any>): Promise<Article[]> => {
    if (config) {
      return requests.get('articles', config);
    }
    return requests.get('articles');
  },
  getAllByCategory: (
    categoryId: number,
    config?: AxiosRequestConfig<any>
  ): Promise<Article[]> => {
    if (config) {
      return requests.get(
        `articles?$filter=CategoryId eq ${categoryId}`,
        config
      );
    }
    return requests.get(`articles?$filter=CategoryId eq ${categoryId}`);
  },
  getOne: (id: number, config?: AxiosRequestConfig<any>): Promise<Article> => {
    if (config) {
      return requests.get(`articles(${id})`, config);
    }
    return requests.get(`articles(${id})`);
  },
  updateOne: (
    id: number,
    article: Article,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.patch(`articles(${id})`, JSON.stringify(article), config);
    }
    return requests.patch(`articles(${id})`, JSON.stringify(article));
  },
  createOne: (article: Partial<Article>, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.post('articles', JSON.stringify(article), config);
    }
    return requests.post('articles', JSON.stringify(article));
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`articles/${id}`, config);
    }
    return requests.delete(`articles/${id}`);
  },
};

export const Categories = {
  getAll: (config?: AxiosRequestConfig<any>): Promise<Category[]> => {
    if (config) {
      return requests.get('categories', config);
    }
    return requests.get('categories');
  },
  getCategory: (
    id: number,
    config?: AxiosRequestConfig<any>
  ): Promise<Category> => {
    if (config) {
      return requests.get(`categories(${id})`, config);
    }
    return requests.get(`categories(${id})`);
  },
  updateOne: (
    id: number,
    category: Category,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.patch(
        `categories(${id})`,
        JSON.stringify(category),
        config
      );
    }
    return requests.patch(`categories(${id})`, JSON.stringify(category));
  },
  createOne: (
    category: Partial<Category>,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.post('categories', JSON.stringify(category), config);
    }
    return requests.post('categories', JSON.stringify(category));
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    requests.delete(`categories/${id}`);
  },
};

export const Budgets = {
  getAll: (config?: AxiosRequestConfig<any>): Promise<Budget[]> => {
    if (config) {
      return requests.get('budgets', config);
    }
    return requests.get('budgets');
  },
  getOne: (id: number, config?: AxiosRequestConfig<any>): Promise<Budget> => {
    if (config) {
      return requests.get(`budgets(${id})`, config);
    }
    return requests.get(`budgets(${id})`);
  },
  getOneByUid: (
    uid: string,
    config?: AxiosRequestConfig<any>
  ): Promise<Budget[]> => {
    if (config) {
      return requests.get(`budgets?$filter=Uid eq '${uid}'`, config);
    }
    return requests.get(`budgets?$filter=Uid eq '${uid}'`);
  },
  updateOne: (id: number, budget: Budget, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.patch(`budgets(${id})`, JSON.stringify(budget), config);
    }
    return requests.patch(`budgets(${id})`, JSON.stringify(budget));
  },
  createOne: (budget: Partial<Budget>, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.post('budgets', JSON.stringify(budget), config);
    }
    return requests.post('budgets', JSON.stringify(budget));
  },
  deleteBudget: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`bugets/${id}`, config);
    }
    return requests.delete(`bugets/${id}`);
  },
};

export const ShoppingCarts = {
  getAll: (config?: AxiosRequestConfig<any>): Promise<ShoppingCart[]> => {
    if (config) {
      return requests.get('shoppingcarts', config);
    }
    return requests.get('shoppingcarts');
  },
  getAllByBudget: (
    budgetId: number,
    config?: AxiosRequestConfig<any>
  ): Promise<ShoppingCart[]> => {
    if (config) {
      return requests.get(
        `shoppingcarts?$filter=BudgetId eq ${budgetId}`,
        config
      );
    }
    return requests.get(`shoppingcarts?$filter=BudgetId eq ${budgetId}`);
  },
  getOne: (
    id: number,
    config?: AxiosRequestConfig<any>
  ): Promise<ShoppingCart> => {
    if (config) {
      return requests.get(`shoppingcarts(${id})`, config);
    }
    return requests.get(`shoppingcarts(${id})`);
  },
  updateOne: (
    id: number,
    cart: ShoppingCart,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.patch(
        `shoppingcarts(${id})`,
        JSON.stringify(cart),
        config
      );
    }
    return requests.patch(`shoppingcarts(${id})`, JSON.stringify(cart));
  },
  createOne: (
    cart: Partial<ShoppingCart>,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.post('shoppingcarts', JSON.stringify(cart), config);
    }
    return requests.post('shoppingcarts', JSON.stringify(cart));
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`shoppingcarts/${id}`, config);
    }
    return requests.delete(`shoppingcarts/${id}`);
  },
};

export const ShoppingCartsHavingArticles = {
  getAll: (
    config?: AxiosRequestConfig<any>
  ): Promise<ShoppingCartHavingArticles[]> => {
    if (config) {
      return requests.get('shoppingcartshavingarticles', config);
    }
    return requests.get('shoppingcartshavingarticles');
  },
  getOne: (
    id: number,
    config?: AxiosRequestConfig<any>
  ): Promise<ShoppingCartHavingArticles> => {
    if (config) {
      return requests.get(`shoppingcartshavingarticles(${id})`, config);
    }
    return requests.get(`shoppingcartshavingarticles(${id})`);
  },
  //req filter by shopping cart id
  getAllByShoppingCart: (
    shoppingCartId: number,
    config?: AxiosRequestConfig<any>
  ): Promise<ShoppingCartHavingArticles[]> => {
    if (config) {
      return requests.get(
        `shoppingcartshavingarticles?$filter=ShoppingCartId eq ${shoppingCartId}`,
        config
      );
    }
    return requests.get(
      `shoppingcartshavingarticles?$filter=ShoppingCartId eq ${shoppingCartId}`
    );
  },
  updateOne: (
    id: number,
    cartArticles: ShoppingCartHavingArticles,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.patch(
        `shoppingcartshavingarticles(${id})`,
        JSON.stringify(cartArticles),
        config
      );
    }
    return requests.patch(
      `shoppingcartshavingarticles(${id})`,
      JSON.stringify(cartArticles)
    );
  },
  createOne: (
    cartArticles: Partial<ShoppingCartHavingArticles>,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.post(
        'shoppingcartshavingarticles',
        JSON.stringify(cartArticles),
        config
      );
    }
    return requests.post(
      'shoppingcartshavingarticles',
      JSON.stringify(cartArticles)
    );
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`shoppingcartshavingarticles/${id}`, config);
    }
    return requests.delete(`shoppingcartshavingarticles/${id}`);
  },
};

export const Orders = {
  getAll: (config?: AxiosRequestConfig<any>): Promise<Order[]> => {
    if (config) {
      return requests.get('orders', config);
    }
    return requests.get('orders');
  },
  getOne: (id: number, config?: AxiosRequestConfig<any>): Promise<Order> => {
    if (config) {
      return requests.get(`orders(${id})`, config);
    }
    return requests.get(`orders(${id})`);
  },
  getAllByUid: (uid: string, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.get(`orders?$filter=Uid eq '${uid}'`, config);
    }
    return requests.get(`orders?$filter=Uid eq '${uid}'`);
  },
  getAllOrdersOrderByUid: (
    config?: AxiosRequestConfig<any>
  ): Promise<Order[]> => {
    if (config) {
      return requests.get('orders?$orderby=Uid asc', config);
    }
    return requests.get('orders?$orderby=Uid asc');
  },
  updateOne: (id: number, order: Order, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.patch(`orders(${id})`, JSON.stringify(order), config);
    }
    return requests.patch(`orders(${id})`, JSON.stringify(order));
  },
  createOne: (order: Partial<Order>, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.post('orders', JSON.stringify(order), config);
    }
    return requests.post('orders', JSON.stringify(order));
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`orders/${id}`, config);
    }
    return requests.delete(`orders/${id}`);
  },
};

export const OrdersHavingArticles = {
  getAll: (
    config?: AxiosRequestConfig<any>
  ): Promise<OrderHavingArticles[]> => {
    if (config) {
      return requests.get('ordershavingarticles', config);
    }
    return requests.get('ordershavingarticles');
  },
  getAllByOrderId: (
    orderId: number,
    config?: AxiosRequestConfig<any>
  ): Promise<OrderHavingArticles[]> => {
    if (config) {
      return requests.get(
        `ordershavingarticles?$filter=OrderId eq ${orderId}`,
        config
      );
    }
    return requests.get(`ordershavingarticles?$filter=OrderId eq ${orderId}`);
  },
  getOne: (
    id: number,
    config?: AxiosRequestConfig<any>
  ): Promise<OrderHavingArticles> => {
    if (config) {
      return requests.get(`ordershavingarticles(${id})`, config);
    }
    return requests.get(`ordershavingarticles(${id})`);
  },
  updateOne: (
    id: number,
    order: OrderHavingArticles,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.patch(
        `ordershavingarticles(${id})`,
        JSON.stringify(order),
        config
      );
    }
    return requests.patch(`ordershavingarticles(${id})`, JSON.stringify(order));
  },
  createOne: (
    order: Partial<OrderHavingArticles>,
    config?: AxiosRequestConfig<any>
  ) => {
    if (config) {
      return requests.post(
        'ordershavingarticles',
        JSON.stringify(order),
        config
      );
    }
    return requests.post('ordershavingarticles', JSON.stringify(order));
  },
  deleteOne: (id: number, config?: AxiosRequestConfig<any>) => {
    if (config) {
      return requests.delete(`ordershavingarticles/${id}`, config);
    }
    return requests.delete(`ordershavingarticles/${id}`);
  },
};
