import { useContext } from 'react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { DefaultButton } from '@fluentui/react';
import { Categories } from '../../../api/provider';
import { Category } from '../../../types/category.types';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupDeleteCategoryProps = {
  hide: () => void;
  categoryId: number;
};

export const PopupDeleteCategory = ({
  hide,
  categoryId,
}: PopupDeleteCategoryProps) => {
  const { categories, setCategories } = useContext(ShopContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const selectedCategory = categories.find(
    (category) => category.Id === categoryId
  );

  /**
   * updates the state with a new list of categories
   * @param updatedCategories  updatedCategories a new list of categories or a function based on the previous list that returns a new list
   */
  const updateCategories = (
    updatedCategories: Category[] | ((prevState: Category[]) => Category[])
  ) => {
    setCategories(updatedCategories);
  };

  const onSave = async () => {
    try {
      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        await Categories.deleteOne(
          categoryId,
          authorizationRequestHeaderConfig
        );
      } else {
        await Categories.deleteOne(categoryId);
      }
      // filter the deleted category from the categories list
      const updatedCategories = categories.filter(
        (category) => category.Id !== categoryId
      );

      // update the categories state with the new list
      updateCategories(updatedCategories);
    } catch (error) {
      // TODO: add proper error handling
      console.log('Error message:' + error);
    }

    hide();
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Kategorie löschen</div>
        <div>
          <div className="tw-flex tw-grow">
            <div>Möchten Sie wirklich {selectedCategory?.Name} löschen?</div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Kategorie löschen abbrechen"
          text="Nein"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          ariaLabel="Kategorie löschen bestätigen"
          text="Ja"
          onClick={onSave}
        />
      </div>
    </>
  );
};
