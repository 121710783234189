import { DefaultButton } from '@fluentui/react';
import { useContext, useEffect } from 'react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { textFieldArticleStyles } from '../../../assets/theme/textfield.article.styles.static';
import { useForm } from 'react-hook-form';
import { ControlledTextfield } from '../../../shared/controlledTextfield/ControlledTextfield';
import { Categories } from '../../../api/provider';
import { useMsal } from '@azure/msal-react';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { EditCategoryForm } from '../../../types/editCategoryForm.types';
import { Category } from '../../../types/category.types';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupEditArticleProps = {
  hide: () => void;
  categoryId: number;
};

export const PopupEditCategory = ({
  hide,
  categoryId,
}: PopupEditArticleProps) => {
  const { accounts: account } = useMsal();
  const { categories, setCategories } = useContext(ShopContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const selectedCategory = categories.find(
    (category) => category.Id === categoryId
  );

  if (selectedCategory === null || selectedCategory === undefined) {
    throw new Error('A problem with the item id occured');
  }

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, isValid, errors },
  } = useForm<EditCategoryForm>({
    defaultValues: {
      Name: selectedCategory.Name ?? '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const onSave = () => {
    handleSubmit(async (data: EditCategoryForm) => {
      const partialCategory: Partial<Category> = {};

      // build patched object when field is dirty
      if (dirtyFields.Name?.valueOf() === true) {
        partialCategory.Name = data.Name;
      }

      // add obj together
      const updateCategory: Category = {
        ...selectedCategory,
        ...partialCategory,
        Modified: new Date().toISOString(),
        ModifiedBy: account[0].localAccountId,
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };
      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        //TODO: handle request properly with timeout and notification
        await Categories.updateOne(
          selectedCategory.Id,
          updateCategory,
          authorizationRequestHeaderConfig
        )
          .then(() => {
            // update the state of the articles array using the setter function of it's context
            const updatedCategory: Category[] = categories.map((category) =>
              category.Id === updateCategory.Id ? updateCategory : category
            );

            setCategories(updatedCategory);
          })
          .catch((error) => {
            //TODO: add proper error handling
            console.log('Error message:' + error);
          });
      } else {
        await Categories.updateOne(selectedCategory.Id, updateCategory)
          .then(() => {
            // update the state of the articles array using the setter function of it's context
            const updatedCategory: Category[] = categories.map((category) =>
              category.Id === updateCategory.Id ? updateCategory : category
            );

            setCategories(updatedCategory);
          })
          .catch((error) => {
            //TODO: add proper error handling
            console.log('Error message:' + error);
          });
      }
    })();

    // close popup on save
    hide();
  };

  useEffect(() => {}, [
    isDirty,
    dirtyFields,
    isValid,
    errors,
    authorizationRequestHeaderConfig,
  ]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Kategorie editieren</div>
        <div>
          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            name="Name"
          />
        </div>
      </div>

      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Abbrechen"
          text="Abbrechen"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          disabled={!isValid || !isDirty}
          ariaLabel="Speichern"
          text="Speichern"
          onClick={handleSubmit(onSave)}
        />
      </div>
    </>
  );
};
