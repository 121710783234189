import { IButtonStyles } from "@fluentui/react";

export const buttonStyles: Partial<IButtonStyles> = {
    rootHovered: [
        {
            background: "none",
            color: "#80C801",
        },
    ],

    label: [
        {
            fontWeight: "bold",
        },
    ],

};