import { ITextFieldStyles } from "@fluentui/react";

export const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        borderColor: "#E9ECF1",
        selectors: {
            ":hover": {
                borderColor: "#E9ECF1",
            },
        },
    },
    field: {
        color: "#E9ECF1",
    },
    fieldGroup: {
        color: "#E9ECF1",
        borderColor: "#E9ECF1",
        selectors: {
            ":hover": {
                borderColor: "#E9ECF1",
            },
            "::after": {
                borderColor: "#E9ECF1",
            },
            "::before": {
                color: "#E9ECF1",
            },
            ">input": {
                "::placeholder": {
                    color: "#E9ECF1",
                },
            },
        },
    },
};