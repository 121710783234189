import { initializeIcons } from "@fluentui/font-icons-mdl2";
import {
  createTheme,
  FontSizes,
  PartialTheme,
  ThemeProvider,
} from "@fluentui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import { LayoutContent } from "../layoutContent/LayoutContent";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import ShopContextProvider from "../../provider/ShopContextProvider";
import UserContextProvider from "../../provider/UserContextProvider";
initializeIcons();

const appTheme: PartialTheme = createTheme({
  defaultFontStyle: {
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: FontSizes.mediumPlus,
  },
});

export const Layout = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <ShopContextProvider>
        <UserContextProvider>
          <React.Fragment>
            <div className="tw-grid tw-grid-cols-1 tw-grid-rows-16 md:tw-grid-rows-8 tw-h-screen">
              <Header />
              <LayoutContent>
                <Outlet />
              </LayoutContent>
              <Footer />
            </div>
          </React.Fragment>
        </UserContextProvider>
      </ShopContextProvider>
    </ThemeProvider>
  );
};
