import { IconButton, IIconProps } from "@fluentui/react";
import { TopMenue } from "../topMenue/TopMenue";
import { FontSizes } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { PopupContainer } from "../popups/popupContainer/PopupContainer";
import { PopupGetCart } from "../popups/popupGetCart/PopupGetCart";
const cartIcon: IIconProps = {
  iconName: "ShoppingCart",
  styles: {
    root: {
      fontSize: FontSizes.size32,
    },
  },
};

export const Header = () => {
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] =
    useBoolean(false);

  return (
    <div className="md:tw-row-span-1 tw-row-span-4">
      <div className="tw-flex tw-h-full tw-w-full tw-justify-center md:tw-items-center tw-items-end">
        <div className="tw-flex md:tw-flex-row tw-flex-col tw-grow tw-justify-center tw-items-center md:tw-items-end tw-border-b-2 tw-border-grey md:tw-mx-32 tw-mx-8">
          <div className="md:tw-basis-1/4 tw-font-['Impact'] tw-font-normal md:tw-text-5xl tw-text-6xl tw-leading-tight md:tw-order-1 tw-order-2">
            <span className="tw-text-green">Real</span>
            <span className="tw-text-grey-dark">Shop</span>
          </div>
          <TopMenue />
          <div className="tw-flex tw-w-full md:tw-basis-1/4 tw-justify-end md:tw-order-3 tw-order-1">
            <IconButton
              className="tw-text-green tw-my-1"
              iconProps={cartIcon}
              title="Warenkorb"
              ariaLabel="Warenkorb"
              onClick={showPopup}
            />
            {isPopupVisible && (
              <PopupContainer
                child={<PopupGetCart hide={hidePopup} />}
                hide={hidePopup}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
