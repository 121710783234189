import { DefaultButton } from '@fluentui/react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { textFieldArticleStyles } from '../../../assets/theme/textfield.article.styles.static';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { useContext, useEffect } from 'react';
import { ControlledTextfield } from '../../../shared/controlledTextfield/ControlledTextfield';
import { useForm } from 'react-hook-form';

import { useMsal } from '@azure/msal-react';
import { Categories } from '../../../api/provider';
import { Category } from '../../../types/category.types';
import { AddCategoryForm } from '../../../types/addCategoryForm.types';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupAddArticleProps = {
  hide: () => void;
};

export const PopupAddCategory = ({ hide }: PopupAddArticleProps) => {
  const { accounts: account } = useMsal();
  const { categories, setCategories } = useContext(ShopContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  /**
   * updates the state with a new list of articles
   * @param updatedCategories  updatedCategoreis a new list of categories or a function based on the previous list that returns a new list
   */
  const updateCategories = (
    updatedCategories: Category[] | ((prevState: Category[]) => Category[])
  ) => {
    setCategories(updatedCategories);
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, isValid, errors },
  } = useForm<AddCategoryForm>({
    defaultValues: {
      Name: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const onSave = () => {
    handleSubmit(async (data: AddCategoryForm) => {
      const partialCategory: Partial<Category> = {};

      // build patched object when field is dirty
      if (dirtyFields.Name && dirtyFields.Name !== undefined) {
        partialCategory.Name = data.Name;
      }

      // generate partial obj for request
      const addCategory: Partial<Category> = {
        ...partialCategory,
        CreatedBy: account?.[0]?.localAccountId,
        Created: new Date().toISOString(),
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };

      try {
        let response;
        if (
          authorizationRequestHeaderConfig.headers?.Authorization !== undefined
        ) {
          response = await Categories.createOne(
            addCategory,
            authorizationRequestHeaderConfig
          );
        } else {
          response = await Categories.createOne(addCategory);
        }
        // filter @odata.context
        //TODO: use transformResponse inside Axios provider
        delete response['@odata.context'];
        // parse categories
        updateCategories([...categories, response as Category]);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
      }
    })();

    // close popup on save
    hide();
  };

  useEffect(() => {}, [isDirty, dirtyFields, isValid, errors]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center tw-space-y-2">
        <div>Kategorie anlegen</div>
        <div className="tw-space-y-2">
          <div className="tw-flex tw-grow">
            <div className="tw-flex tw-w-full tw-justify-center tw-items-center">
              <DefaultButton
                text="Datei hochladen"
                aria-label="Datei hochladen"
                disabled
                styles={buttonStyles}
                className="tw-bg-grey-dark tw-text-white"
              >
                <input type="file" style={{ display: 'none' }} />
              </DefaultButton>
            </div>
          </div>
          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            name="Name"
            placeholder="Namen eingeben"
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Abbrechen"
          text="Abbrechen"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          ariaLabel="Speichern"
          disabled={!isValid || !isDirty}
          text="Speichern"
          onClick={handleSubmit(onSave)}
        />
      </div>
    </>
  );
};
