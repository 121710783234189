import { useMsal } from '@azure/msal-react';
import { DefaultButton } from '@fluentui/react';
import { useContext } from 'react';
import { ShoppingCartsHavingArticles } from '../../../api/provider';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { Article } from '../../../types/article.types';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { UserContext } from '../../../provider/UserContextProvider';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupGetArticleProps = {
  article: Article | null;
  hide: () => void;
};

export const PopupGetArticle = ({ article, hide }: PopupGetArticleProps) => {
  const { accounts: account } = useMsal();
  const { categories, isShopContextLoading } = useContext(ShopContext);
  const { cart } = useContext(UserContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  /**
   * Async function for adding an article to the shopping cart
   */
  const addToCart = async (): Promise<void> => {
    if (article !== null && cart !== undefined) {
      //TODO: add within the function a way to display loading in the frontend
      // and error handling for better UX.
      const newDate = new Date().toISOString();
      const newCart = {
        Id: 0,
        ShoppingCartId: cart?.Id,
        ArticleId: article?.Id,
        Created: newDate,
        CreatedBy: account[0].localAccountId,
        Modified: newDate,
        ModifiedBy: account[0].localAccountId,
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };

      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        await ShoppingCartsHavingArticles.createOne(
          newCart,
          authorizationRequestHeaderConfig
        );
      } else {
        await ShoppingCartsHavingArticles.createOne(newCart);
      }
    }
  };

  return (
    <>
      {isShopContextLoading ? (
        <div className="tw-flex tw-justify-center">
          <div className="skeleton skeleton-text"></div>
        </div>
      ) : article != null && categories != null ? (
        <>
          <div className="tw-flex tw-flex-col tw-text-center">
            <div>
              {
                categories.filter(
                  (category) => category.Id === article.CategoryId
                )[0].Name
              }
            </div>
            <div className="tw-flex tw-grow tw-flex-col">
              <div className="tw-text-2xl">{article.Title}</div>
              <div className="tw-text-xl">{article.Price ?? '0.00'} €</div>
            </div>
          </div>
          <div className="tw-flex tw-justify-center tw-pt-8">
            <DefaultButton
              className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
              styles={buttonStyles}
              ariaLabel="Abbrechen"
              text="Abbrechen"
              onClick={hide}
            />

            <DefaultButton
              className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
              styles={buttonStyles}
              ariaLabel="In den Warenkorb legen"
              text="In den Warenkorb legen"
              onClick={addToCart}
            />
          </div>
        </>
      ) : (
        <>Etwas ist schiefgelaufen! Bitte kontaktiere den Support</>
      )}
    </>
  );
};
