import {
  DocumentCard,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
  SearchBox,
} from "@fluentui/react";
import { useContext} from "react";
import { useNavigate } from "react-router-dom";
import { cardStyles } from "../../assets/theme/cards.styles.static";
import { searchBoxStyles } from "../../assets/theme/searchfield.styles.static";
import { ShopContext } from "../../provider/ShopContextProvider";

export const Home = () => {
  const { categories, isShopContextLoading } = useContext(ShopContext);

  let navigate = useNavigate();
  function handleClick(category: number) {
    navigate(`/articles/${category}`, {
      state: { articles: category },
    });
  }

  return (
    <>
      <div className="tw-row-span-1 tw-flex tw-justify-center tw-items-center tw-grow tw-italic md:tw-text-3xl tw-text-2xl tw-text-grey">
        <div className="md:tw-leading-extra-loose">
          Willkommen im&nbsp;<span className="tw-text-green">Real</span>Shop!
        </div>
      </div>
      <div className="md:tw-row-span-4 tw-row-span-9 tw-justify-center tw-items-center">
        <div className="tw-bg-grey-light tw-flex tw-flex-row tw-justify-center tw-items-center tw-h-full">
          <div
            className={
              categories.length > 0
                ? "tw-grid tw-gap-2 tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-h-full tw-w-full md:tw-mx-32 tw-mx-8 tw-py-4"
                : "tw-flex tw-h-full tw-w-full tw-py-4 tw-justify-center tw-items-center tw-text-grey tw-text-xl"
            }
          >
            {isShopContextLoading ? (
              <div className="loading-spinner"></div>
            ) : categories.length > 0 ? (
              categories.map((category) => (
                <DocumentCard
                  styles={cardStyles}
                  aria-label={category.Name}
                  className="tw-p-2 md:tw-p-6 lg:tw-p-4"
                  onClick={() => handleClick(category.Id)}
                  key={category.Id}
                >
                  <DocumentCardImage
                    imageFit={ImageFit.cover}
                    // imageSrc={category.img} TODO: add img functionality
                  />
                  <DocumentCardTitle
                    className="tw-flex tw-justify-center tw-items-center tw-text-grey hover:tw-text-white"
                    title={category.Name}
                  />
                </DocumentCard>
              ))
            ) : (
              <p>Keine Kategorien gefunden.</p>
            )}
          </div>
        </div>
      </div>
      <div className="tw-row-span-1 tw-flex md:tw-flex-col tw-justify-center tw-items-center tw-text-grey">
        <div className="tw-hidden md:tw-block">
          Deinen Artikel nicht gefunden?
        </div>
        <SearchBox
          className="tw-mt-2 md:tw-mt-0"
          styles={searchBoxStyles}
          placeholder="Suche einen Artikel"
        />
      </div>
    </>
  );
};
