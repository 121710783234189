import { DefaultButton, IDropdownOption } from '@fluentui/react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { textFieldArticleStyles } from '../../../assets/theme/textfield.article.styles.static';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { useContext, useEffect } from 'react';
import { ControlledTextfield } from '../../../shared/controlledTextfield/ControlledTextfield';
import { AddArticleForm } from '../../../types/addArticleForm.types';
import { useForm } from 'react-hook-form';
import { dropdownStyles } from '../../../assets/theme/dropdown.styles.static';
import { ControlledDropdown } from '../../../shared/controlledDropdown/ControlledDropdown';
import { Article } from '../../../types/article.types';
import { useMsal } from '@azure/msal-react';
import { Articles } from '../../../api/provider';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupAddArticleProps = {
  hide: () => void;
};

export const PopupAddArticle = ({ hide }: PopupAddArticleProps) => {
  const { accounts: account } = useMsal();
  const { articles, categories, setArticles } = useContext(ShopContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);

  const categoryDropdownOptions: IDropdownOption[] = categories.map(
    ({ Id, Name }) => ({
      key: Id,
      text: Name,
    })
  );

  /**
   * updates the state with a new list of articles
   * @param updatedArticles updatedArticles a new list of articles or a function based on the previous list that returns a new list
   */
  const updateArticles = (
    updatedArticles: Article[] | ((prevState: Article[]) => Article[])
  ) => {
    setArticles(updatedArticles);
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, isValid, errors },
  } = useForm<AddArticleForm>({
    defaultValues: {
      Title: '',
      Price: '',
      CategoryId: undefined,
    },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  /**
   * Function that handles the update of an article
   */
  const onUpdateArticle = () => {
    handleSubmit(async (data: AddArticleForm) => {
      const partialArticle: Partial<Article> = {};

      // build patched object when field is dirty
      if (dirtyFields.Title && dirtyFields.Title !== undefined) {
        partialArticle.Title = data.Title;
      }

      if (dirtyFields.CategoryId && dirtyFields.CategoryId !== undefined) {
        partialArticle.CategoryId = +data.CategoryId;
      }

      if (dirtyFields.Price && dirtyFields.Price !== undefined) {
        partialArticle.Price = +data.Title;
      }

      // generate partial obj for request
      const addArticle: Partial<Article> = {
        ...partialArticle,
        CreatedBy: account?.[0]?.localAccountId,
        Created: new Date().toISOString(),
        TenantId: `${process.env.REACT_APP_TENANT_ID}`,
      };

      try {
        let response;
        if (
          authorizationRequestHeaderConfig.headers?.Authorization !== undefined
        ) {
          response = await Articles.createOne(
            addArticle,
            authorizationRequestHeaderConfig
          );
        } else {
          response = await Articles.createOne(addArticle);
        }

        // filter @odata.context
        //TODO: use transformResponse inside Axios provider
        delete response['@odata.context'];
        // parse articles
        updateArticles([...articles, response as Article]);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
      }
    })();

    // close popup on save
    hide();
  };

  useEffect(() => {}, [
    isDirty,
    dirtyFields,
    isValid,
    errors,
    authorizationRequestHeaderConfig,
  ]);

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center tw-space-y-2">
        <div>Artikel anlegen</div>
        <div className="tw-space-y-2">
          <div className="tw-flex tw-grow">
            <div>
              <ControlledDropdown
                options={categoryDropdownOptions}
                styles={dropdownStyles}
                control={control}
                name="CategoryId"
                placeholder="Kategorie wählen"
              />
            </div>
            <div className="tw-flex tw-w-full tw-justify-end tw-items-center">
              <DefaultButton
                text="Datei hochladen"
                aria-label="Datei hochladen"
                disabled
                styles={buttonStyles}
                className="tw-bg-grey-dark tw-text-white"
              >
                <input type="file" style={{ display: 'none' }} />
              </DefaultButton>
            </div>
          </div>
          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            name="Title"
            placeholder="Titel eingeben"
          />

          <ControlledTextfield
            styles={textFieldArticleStyles}
            control={control}
            type="number"
            rules={{
              min: {
                message: 'Bitte minimalen Eintrag mit mindestens 0€ beziffern',
                value: 0,
              },
              valueAsNumber: true,
            }}
            name="Price"
            placeholder="Preis eingeben"
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Abbrechen"
          text="Abbrechen"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          ariaLabel="Speichern"
          disabled={!isValid || !isDirty}
          text="Speichern"
          onClick={handleSubmit(onUpdateArticle)}
        />
      </div>
    </>
  );
};
