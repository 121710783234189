import { ISearchBoxStyles } from "@fluentui/react";

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: 200,
      borderColor: "#E9ECF1",
      selectors: {
        ":hover": {
          borderColor: "#E9ECF1",
        },
        ":hover .ms-SearchBox-iconContainer": {
          color: "#E9ECF1",
        },
        "&.is-active::after": {
          borderColor: "#E9ECF1",
        },
        '>div[class*="iconContainer"]:hover': {
          color: "#E9ECF1",
        },
        ">input": {
          "::placeholder": {
            color: "#E9ECF1",
          },
        },
      },
    },
    field: {
      color: "#E9ECF1",
    },
    iconContainer: {
      color: "#E9ECF1",
      selectors: {
        ":hover": {
          color: "#E9ECF1",
        },
      },
    },
  };