import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "./pages/home/Home";
import { Administration } from "./pages/administration/Administration";
import { ArticleView } from "./pages/articleView/ArticleView";
import { Layout } from "./components/layout/Layout";
import AllOrdersContextProvider from "./provider/AllOrdersContextProvider";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout></Layout>}>
          <Route path="/" element={<Home />} />
          <Route path="/articles/:categoryId" element={<ArticleView />} />
          <Route
            path="/administration"
            element={
              <AllOrdersContextProvider>
                <Administration />
              </AllOrdersContextProvider>
            }
          />
        </Route>
        <Route path="*" element={<p>Something went wrong</p>} />
      </Routes>
    </Router>
  );
}

export default App;
