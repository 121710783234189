import { ITextFieldProps, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "../../types/hookForm.types";

export const ControlledTextfield = ({
  ...props
}: HookFormProps & ITextFieldProps) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error },
      }) => {
        return (
          <TextField
            {...props}
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            defaultValue={undefined}
            errorMessage={error && error.message}
          />
        );
      }}
    />
  );
};
