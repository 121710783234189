export const msalConfig = {
  auth: {
    clientId: '2ebcb0be-70d6-4f33-9d5d-bfc26931a81e',
    authority: 'https://login.microsoftonline.com/organizations',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
};
