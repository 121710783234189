import {
  FocusTrapZone,
  FontSizes,
  IconButton,
  IIconProps,
  Layer,
  Overlay,
  Popup,
} from "@fluentui/react";
import { ReactNode } from "react";
import { buttonStyles } from "../../../assets/theme/button.styles.static";
import { popupStyles } from "../../../assets/theme/popup.styles.static";

type PopupContainerProps = {
  child: ReactNode;
  hide: () => void;
};

const cancelIcon: IIconProps = {
  iconName: "Cancel",
  styles: {
    root: {
      fontSize: FontSizes.size16,
    },
  },
};

export const PopupContainer = ({ child, hide }: PopupContainerProps) => {
  return (
    <Layer>
      <Popup
        className={popupStyles.root}
        role="dialog"
        aria-modal="true"
        onDismiss={hide}
      >
        <Overlay onClick={hide} />
        <FocusTrapZone>
          <div role="document" className={popupStyles.content}>
            <div className="tw-bg-white tw-p-6 tw-rounded tw-flex tw-flex-col tw-text-grey">
              <div className="tw-flex tw-justify-end">
                <IconButton
                  className="tw-text-grey"
                  iconProps={cancelIcon}
                  styles={buttonStyles}
                  title="Schließen"
                  ariaLabel="Schließen"
                  onClick={hide}
                />
              </div>
              {child}
            </div>
          </div>
        </FocusTrapZone>
      </Popup>
    </Layer>
  );
};
