import { mergeStyleSets } from "@fluentui/merge-styles";

export const popupStyles = mergeStyleSets({
    root: {
        background: "rgba(0, 0, 0, 0.2)",
        bottom: "0",
        left: "0",
        position: "fixed",
        right: "0",
        top: "0",
    },
    content: {
        left: "50%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
});