import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OAuth2HandlerContextType } from '../types/oAuth2HandlerContext.types';
import { AxiosRequestConfig } from 'axios';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

type OAuth2HandlerContextProviderProps = {
  children?: ReactNode;
};

export const OAuth2HandlerContext = createContext<OAuth2HandlerContextType>({
  authorizationRequestHeaderConfig: {},
  buildAxiosRequestAuthorizationConfig: async () => {},
});

/**
 * provider for creating the authorization configuration for axios requests
 * @param children ReactNode where the provider will be used
 * @returns the context provider
 */
const OAuth2HandlerContextProvider = ({
  children,
}: OAuth2HandlerContextProviderProps) => {
  const [
    authorizationRequestHeaderConfig,
    setAuthorizationRequestHeaderConfig,
  ] = useState<AxiosRequestConfig<any>>({});
  const { instance, accounts, inProgress } = useMsal();
  const ignore_fetchData = useRef(false);

  /**
   * Async function for getting the access token from MS identity plattform
   * see reference: https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
   */
  const acquireAccessToken = useCallback(async (): Promise<string> => {
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_AUTH_SCOPES}`],
      account: accounts[0],
    };

    if (inProgress === InteractionStatus.None) {
      try {
        const accessTokenResponse = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        // acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        await window.sessionStorage.setItem('accessToken', accessToken);
        return accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
        return '';
      }
    }

    return '';
  }, [accounts, inProgress, instance]);

  /**
   * Async function for building the authorization header
   * that will be provided for context and or components to authentificate against the API
   * Note: This functiuon is also exposed as possible fallback
   * from the context to build the config in other components/ contexts
   */
  const buildAxiosRequestAuthorizationConfig = useCallback(
    async (accessToken?: string): Promise<any> => {
      if (!accessToken) {
        return {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem(
              'accessToken'
            )}`,
          },
        };
      }
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    },
    []
  );

  /**
   * Async function wrapper that handles the access token call, build and sets the config state
   */
  const getAxiosRequestAuthorizationConfig = useCallback(async () => {
    try {
      const token = await acquireAccessToken();
      const config = await buildAxiosRequestAuthorizationConfig(token);
      setAuthorizationRequestHeaderConfig(config);
    } catch (error) {
      console.log(error);
    }
  }, [acquireAccessToken, buildAxiosRequestAuthorizationConfig]);

  useEffect(() => {
    // fix for react with ignore to not render the useEffect twice in development mode
    // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    if (authorizationRequestHeaderConfig.headers?.Authorization === undefined) {
      if (ignore_fetchData.current === false) {
        getAxiosRequestAuthorizationConfig();
        return () => {
          ignore_fetchData.current = true;
        };
      }
    }
    return () => {
      ignore_fetchData.current = true;
    };
  }, [getAxiosRequestAuthorizationConfig, authorizationRequestHeaderConfig]);

  useEffect(() => {}, [authorizationRequestHeaderConfig]);

  const OAuth2HandlerContextValue: OAuth2HandlerContextType = {
    authorizationRequestHeaderConfig,
    buildAxiosRequestAuthorizationConfig,
  };

  return (
    <OAuth2HandlerContext.Provider value={OAuth2HandlerContextValue}>
      {children}
    </OAuth2HandlerContext.Provider>
  );
};

export default OAuth2HandlerContextProvider;
