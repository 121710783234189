import {
  DocumentCard,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
} from "@fluentui/react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { cardStyles } from "../../assets/theme/cards.styles.static";
import { Article } from "../../types/article.types";
import { useBoolean } from "@fluentui/react-hooks";
import { PopupContainer } from "../../components/popups/popupContainer/PopupContainer";
import { PopupGetArticle } from "../../components/popups/popupGetArticle/PopupGetArticle";
import { ShopContext } from "../../provider/ShopContextProvider";

export const ArticleView = () => {
  const { categoryId } = useParams();
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] =
    useBoolean(false);

  const { articles, isShopContextLoading } = useContext(ShopContext);

  if (categoryId === undefined) {
    throw new Error("Endpoint Parameter not found");
  }

  const filteredArticles = articles.filter(
    (article) => article.CategoryId === parseInt(categoryId)
  );

  return (
    <>
      <div className="tw-row-span-1 tw-flex tw-justify-center tw-items-center tw-grow tw-italic md:tw-text-3xl tw-text-2xl tw-text-grey">
        <div className="md:tw-leading-extra-loose">Artikelansicht</div>
      </div>
      <div className="md:tw-row-span-4 tw-row-span-9 tw-justify-center tw-items-center">
        <div className="tw-bg-grey-light tw-flex tw-flex-row tw-justify-center tw-items-center tw-h-full">
          <div
            className={
              articles.length > 0
                ? "tw-grid tw-gap-2 tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-h-full tw-w-full md:tw-mx-32 tw-mx-8 tw-py-4"
                : "tw-flex tw-h-full tw-w-full tw-py-4 tw-justify-center tw-items-center tw-text-grey tw-text-xl"
            }
          >
            {isShopContextLoading ? (
              <div className="loading-spinner"></div>
            ) : filteredArticles.length > 0 ? (
              filteredArticles.map((article) => (
                <DocumentCard
                  styles={cardStyles}
                  aria-label={article.Title}
                  className="tw-p-2 md:tw-p-6 lg:tw-p-4"
                  key={article.Id}
                  onClick={() => {
                    setSelectedArticle(article);
                    showPopup();
                  }}
                >
                  <DocumentCardImage
                    imageFit={ImageFit.cover}
                    // imageSrc={article.img} TODO: add img functionality
                  />
                  <DocumentCardTitle
                    className="tw-flex tw-justify-center tw-items-center tw-text-grey hover:tw-text-white"
                    title={article.Title}
                  />
                </DocumentCard>
              ))
            ) : (
              <p>Keine Artikel gefunden.</p>
            )}
          </div>
        </div>
      </div>
      <div className="tw-row-span-1" />
      {isPopupVisible && (
              <PopupContainer
                child={
                  <PopupGetArticle hide={hidePopup} article={selectedArticle} />
                }
                hide={hidePopup}
              />
            )}
    </>
  );
};
