import { useContext } from 'react';
import { buttonStyles } from '../../../assets/theme/button.styles.static';
import { ShopContext } from '../../../provider/ShopContextProvider';
import { DefaultButton } from '@fluentui/react';
import { Articles } from '../../../api/provider';
import { Article } from '../../../types/article.types';
import { OAuth2HandlerContext } from '../../../provider/OAuth2HandlerContextProvider';

type PopupDeleteArticleProps = {
  hide: () => void;
  articleId: number;
};

export const PopupDeleteArticle = ({
  hide,
  articleId,
}: PopupDeleteArticleProps) => {
  const { articles, setArticles } = useContext(ShopContext);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const selectedArticle = articles.find((article) => article.Id === articleId);

  /**
   * updates the state with a new list of articles
   * @param updatedArticles  updatedArticles a new list of articles or a function based on the previous list that returns a new list
   */
  const updateArticles = (
    updatedArticles: Article[] | ((prevState: Article[]) => Article[])
  ) => {
    setArticles(updatedArticles);
  };

  const onSave = async () => {
    try {
      if (
        authorizationRequestHeaderConfig.headers?.Authorization !== undefined
      ) {
        await Articles.deleteOne(articleId, authorizationRequestHeaderConfig);
      } else {
        await Articles.deleteOne(articleId);
      }

      // filter the deleted article from the articles list
      const updatedArticles = articles.filter(
        (article) => article.Id !== articleId
      );

      // update the articles state with the new list
      updateArticles(updatedArticles);
    } catch (error) {
      // TODO: add proper error handling
      console.log('Error message:' + error);
    }

    hide();
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Artikel löschen</div>
        <div>
          <div className="tw-flex tw-grow">
            <div>Möchten Sie wirklich {selectedArticle?.Title} löschen?</div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded tw-mr-2"
          styles={buttonStyles}
          ariaLabel="Artikel löschen abbrechen"
          text="Nein"
          onClick={hide}
        />

        <DefaultButton
          className="tw-text-white tw-bg-green tw-rounded tw-border-none tw-ml-2"
          styles={buttonStyles}
          ariaLabel="Artikel löschen bestätigen"
          text="Ja"
          onClick={onSave}
        />
      </div>
    </>
  );
};
