import { DefaultButton } from "@fluentui/react";
import { useContext } from "react";
import { buttonStyles } from "../../../assets/theme/button.styles.static";
import { UserContext } from "../../../provider/UserContextProvider";

type PopupGetBudgetProps = {
  hide: () => void;
};

export const PopupGetBudget = ({ hide }: PopupGetBudgetProps) => {
  const { budget, isUserContextLoading } = useContext(UserContext);
  return (
    <>
      <div className="tw-flex tw-flex-col tw-text-center">
        <div>Dein Budget beträgt:</div>
        <span className="tw-text-green">
          {isUserContextLoading ? (
            <div className="skeleton skeleton-text"></div>
          ) : budget !== undefined ? (
            <>{budget.Total} €</>
          ) : (
            <>Fehler, bitte kontaktiere deinen Teamleiter.</>
          )}
        </span>
      </div>
      <div className="tw-flex tw-justify-center tw-pt-8">
        <DefaultButton
          className="tw-text-white tw-bg-grey-dark tw-rounded"
          styles={buttonStyles}
          ariaLabel="Okay!"
          text="Okay!"
          onClick={hide}
        />
      </div>
    </>
  );
};
