import { Pivot, PivotItem } from '@fluentui/react';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ArticleCategoryMap } from '../../types/articleCategoryMap';
import { ShopContext } from '../../provider/ShopContextProvider';
import TableWithPopups from '../../shared/tableWithPopups/TableWithPopups';
import { AllOrdersContext } from '../../provider/AllOrdersContextProvider';
import { DetailsListWithGroups } from '../../components/detailsListWithGroups/DetailsListWithGroups';
import { OrdersPerUser } from '../../types/ordersPerUser.types';

export const Administration = memo(() => {
  const { articles, categories } = useContext(ShopContext);
  const [isLoading, setIsLoading] = useState(true);
  const [articleCategoryMap, setArticleCategoryMap] = useState<
    ArticleCategoryMap[]
  >([]);
  const { ordersPerUser, isAllOrdersContextLoading } =
    useContext(AllOrdersContext);
  const ignore_setArticleCategoryMap = useRef(false);
  const ignore_fetchData2 = useRef(false);

  const fetchData = useCallback(async () => {
    try {
      const map: ArticleCategoryMap[] = articles.map((article) => {
        const categoryName =
          categories.find((category) => category.Id === article.CategoryId)
            ?.Name || null;
        return { categoryName, article };
      });

      setArticleCategoryMap(map);
      setIsLoading(false);
    } catch (error) {
      // TODO: add proper error handling
      console.log('Error message:' + error);
      setIsLoading(false);
    }
  }, [articles, categories]);

  useEffect(() => {
    // fix for react with ignore to not render the useEffect twice in development mode
    // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    if (ignore_fetchData2.current === false) {
      setIsLoading(true);

      fetchData();
      return () => {
        ignore_setArticleCategoryMap.current = true;
      };
    }
  }, [fetchData]);

  useEffect(() => {
    // fix for react with ignore to not render the useEffect twice in development mode
    // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    if (ignore_setArticleCategoryMap.current === false) {
      setArticleCategoryMap((prevState) => {
        const map: ArticleCategoryMap[] = prevState.map((item) => {
          const updatedArticle = articles.find(
            (article) => article.Id === item.article.Id
          );
          return {
            categoryName: item.categoryName,
            article: updatedArticle || item.article,
          };
        });
        return map;
      });
      return () => {
        ignore_setArticleCategoryMap.current = true;
      };
    }
  }, [articles]);

  return (
    <>
      <div className="tw-row-span-1 tw-flex tw-justify-center tw-items-center tw-grow tw-italic md:tw-text-3xl tw-text-2xl tw-text-grey">
        <div className="md:tw-leading-extra-loose">Administration</div>
      </div>
      <div className="md:tw-row-span-4 tw-row-span-9 tw-justify-center tw-items-center md:tw-mx-32 tw-mx-8">
        <div className=" tw-flex tw-flex-col tw-justify-center tw-items-start tw-h-full">
          <div className="tw-h-full tw-w-full">
            <Pivot aria-label="Auswahl Administration">
              <PivotItem headerText="Bestellungen">
                {isAllOrdersContextLoading ? (
                  <div className="loading-spinner"></div>
                ) : ordersPerUser.every(
                    (userOrders: OrdersPerUser) => userOrders.orders.length > 0
                  ) ? (
                  <>
                    <DetailsListWithGroups
                      ordersPerUser={ordersPerUser}
                    ></DetailsListWithGroups>
                  </>
                ) : (
                  <>Keine Daten gefunden.</>
                )}
              </PivotItem>
              <PivotItem
                headerText="Artikelverwaltung"
                headerButtonProps={{
                  'data-order': 1,
                }}
              >
                <TableWithPopups
                  data={articles}
                  tableType="Artikel"
                  isDataLoading={isLoading}
                  articleCategoryMap={articleCategoryMap}
                />
              </PivotItem>
              <PivotItem headerText="Kategorienverwaltung">
                <TableWithPopups
                  data={categories}
                  tableType="Kategorie"
                  isDataLoading={isLoading}
                />
              </PivotItem>
            </Pivot>
          </div>
        </div>
      </div>
      <div className="tw-row-span-1"></div>
    </>
  );
});
