import { CommandBarButton } from "@fluentui/react";
import { buttonStyles } from "../../assets/theme/button.styles.static";
import { PermissionGate } from "../permissions/PersmissionGate";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { SCOPES } from "../../enums/scopes.enums";

export const Footer = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <div className="tw-row-span-1">
      <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
        <div className="tw-flex tw-flex-row tw-grow tw-justify-center tw-items-center tw-border-t-2 tw-border-grey md:tw-mx-32 tw-mx-8">
          <PermissionGate scopes={[SCOPES.canEdit]}>
            <CommandBarButton
              className="tw-text-grey tw-mx-4 tw-my-2"
              styles={buttonStyles}
              text="Verwaltung"
              href="/administration"
            />
          </PermissionGate>
          <AuthenticatedTemplate>
            <CommandBarButton
              className="tw-text-grey tw-mx-4 tw-my-2"
              styles={buttonStyles}
              text="Logout"
              onClick={() => handleLogout("redirect")}
            />
          </AuthenticatedTemplate>
        </div>
      </div>
    </div>
  );
};