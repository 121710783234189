import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ShopContextType } from '../types/shopContext.types';
import { Article } from '../types/article.types';
import { Articles, Categories } from '../api/provider';
import { Category } from '../types/category.types';
import { OAuth2HandlerContext } from './OAuth2HandlerContextProvider';
import { AxiosRequestConfig } from 'axios';

export const ShopContext = createContext<ShopContextType>({
  articles: [],
  categories: [],
  isShopContextLoading: true,
  setArticles: () => {},
  setCategories: () => {},
});

type ShopContextProviderProps = {
  children: ReactNode;
};

/**
 * provider for storing the edited values inside the administration component
 * @param children ReactNode where the provider will be used
 * @returns the context provider
 */
const ShopContextProvider = ({ children }: ShopContextProviderProps) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isShopContextLoading, setIsShopContextLoading] = useState(true);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);
  const ignore_fetchData = useRef(false);

  /**
   * Function to fetch all Categories objects
   * from API via axios request
   */
  const fetchCategories = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      try {
        let categories: Category[] = [];
        if (authHeaderConfig) {
          categories = await Categories.getAll(authHeaderConfig);
        } else {
          categories = await Categories.getAll();
        }
        await setCategories(categories);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsShopContextLoading(false);
      }
    },
    []
  );

  /**
   * Function to fetch all Articles objects
   * from API via axios request
   */
  const fetchArticles = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig<any>) => {
      let articles: Article[];
      try {
        if (authHeaderConfig) {
          articles = await Articles.getAll(authHeaderConfig);
        } else {
          articles = await Articles.getAll();
        }
        await setArticles(articles);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsShopContextLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      // fix for react with ignore to not render the useEffect twice in development mode
      // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
      if (ignore_fetchData.current === false) {
        setIsShopContextLoading(true);
        fetchArticles(authorizationRequestHeaderConfig);
        fetchCategories(authorizationRequestHeaderConfig);
        setIsShopContextLoading(false);
        return () => {
          ignore_fetchData.current = true;
        };
      }
    }
  }, [fetchCategories, fetchArticles, authorizationRequestHeaderConfig]);

  if (isShopContextLoading) {
    //TODO: add a text value for missing data
    return <></>;
  }

  const shopContextValue: ShopContextType = {
    articles,
    categories,
    isShopContextLoading: isShopContextLoading,
    setArticles,
    setCategories,
  };

  return (
    <ShopContext.Provider value={shopContextValue}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
