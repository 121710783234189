import { CommandBarButton } from "@fluentui/react";
import { PopupContainer } from "../popups/popupContainer/PopupContainer";
import { PopupGetBudget } from "../popups/popupGetBudget/PopupGetBudget";
import { useBoolean } from "@fluentui/react-hooks";
import { PopupGetOrders } from "../popups/popupGetOrders/PopupGetOrders";
import { buttonStyles } from "../../assets/theme/button.styles.static";
import UserOrdersContextProvider from "../../provider/UserOrdersContextProvider";

export const TopMenue = () => {
  const [
    isPopupBudgetVisible,
    { setTrue: showBudgetPopup, setFalse: hideBudgetPopup },
  ] = useBoolean(false);
  const [
    isPopupOrdersVisible,
    { setTrue: showOrdersPopup, setFalse: hideOrdersPopup },
  ] = useBoolean(false);
  return (
    <div className="tw-flex md:tw-basis-1/2 tw-flex-row tw-h-full tw-w-full tw-justify-center md:tw-order-2 tw-order-3">
      <CommandBarButton
        className="tw-text-grey tw-mx-6 tw-my-2"
        styles={buttonStyles}
        text="Start"
        href="/"
      />
      <CommandBarButton
        className="tw-text-grey tw-mx-6 tw-my-2"
        styles={buttonStyles}
        text="Bestellungen"
        aria-label="Bestellungen"
        onClick={showOrdersPopup}
      />
      <CommandBarButton
        className="tw-text-grey tw-mx-tw-my-2"
        styles={buttonStyles}
        text="Budget"
        aria-label="Budget"
        onClick={showBudgetPopup}
      />
      {isPopupBudgetVisible && (
        <PopupContainer
          child={<PopupGetBudget hide={hideBudgetPopup} />}
          hide={hideBudgetPopup}
        />
      )}
      {isPopupOrdersVisible && (
        <PopupContainer
          child={
            <UserOrdersContextProvider>
              <PopupGetOrders />
            </UserOrdersContextProvider>
          }
          hide={hideOrdersPopup}
        />
      )}
    </div>
  );
};
