import { IDocumentCardStyles } from "@fluentui/react";

export const cardStyles: Partial<IDocumentCardStyles> = {
  root: {
    minWidth: 140,
    maxWidth: 260,
    selectors: {
      ":hover": {
        background: "#5A6D87",
      },
      ":hover .ms-DocumentCardTitle": {
        color: "white",
      },
    },
  },
};