import usePagination, { DOTS } from "../../customHooks/usePagination";

// code rewritten from js in ts from this tutorial: https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
type CustomPaginationProps =  {
  onPageChange: (pageNumber: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}

export const CustomPagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: CustomPaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  const containerClassName = className
    ? `tw-flex tw-list-none ${className}`
    : "tw-flex tw-list-none";

  const itemClassName = className
    ? `tw-text-grey tw-px-3 tw-h-8 tw-text-center tw-mx-1 tw-flex tw-items-center tw-box-border tw-rounded-full tw-leading-4 tw-text-xs tw-min-w-8 ${className}`
    : "tw-text-grey tw-px-3 tw-h-8 tw-text-center tw-mx-1 tw-flex tw-items-center tw-box-border tw-rounded-full tw-leading-4 tw-text-xs tw-min-w-8";

  return (
    <ul className={containerClassName}>
      <li
        className={`${itemClassName} ${currentPage === 1 ? "disabled" : ""}`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`dots-${index}`}
              className="tw-text-grey tw-px-3 tw-h-8 tw-text-center tw-mx-1 tw-flex tw-items-center tw-box-border tw-rounded-full tw-leading-4 tw-text-xs tw-min-w-8 dots"
            >
              &#8230;
            </li>
          );
        }
        return (
          <li
            key={`page-${pageNumber}`}
            className={`${itemClassName} ${
              pageNumber === currentPage ? "selected" : ""
            }`}
            onClick={() => onPageChange(+pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`${itemClassName} ${
          currentPage === lastPage ? "disabled" : ""
        }`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};
