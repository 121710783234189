import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Orders, OrdersHavingArticles } from '../api/provider';
import { OrderHavingArticles } from '../types/orderHavingArticles.types';
import { AllOrdersContextType } from '../types/allOrdersContext.types';
import { OrdersPerUser } from '../types/ordersPerUser.types';
import { OAuth2HandlerContext } from './OAuth2HandlerContextProvider';
import { AxiosRequestConfig } from 'axios';
import { Order } from '../types/order.types';

type UserOrdersContextProviderProps = {
  children: ReactNode;
};

export const AllOrdersContext = createContext<AllOrdersContextType>({
  ordersPerUser: [],
  isAllOrdersContextLoading: true,
  setOrdersPerUser: () => {},
});

/**
 * provider for storing all user orders with articles
 * @param children ReactNode where the provider will be used
 * @returns the context provider
 */
const AllOrdersContextProvider = ({
  children,
}: UserOrdersContextProviderProps) => {
  const [ordersPerUser, setOrdersPerUser] = useState<OrdersPerUser[]>([]);
  const [isAllOrdersContextLoading, setIsUserOrdersContextLoading] =
    useState(true);
  const ignore_fetchData = useRef(false);
  const { authorizationRequestHeaderConfig } = useContext(OAuth2HandlerContext);

  /**
   * Async function to get all orders per user
   */
  const getAllOrdersPerUser = useCallback(
    async (
      authHeaderConfig?: AxiosRequestConfig<any>
    ): Promise<Map<string, number[]>> => {
      let allOrdersPerUser = new Map<string, number[]>();
      try {
        let orders: Order[];

        if (authHeaderConfig) {
          orders = await Orders.getAllOrdersOrderByUid(authHeaderConfig);
        } else {
          orders = await Orders.getAllOrdersOrderByUid();
        }

        orders.forEach((order) => {
          const orderArray = allOrdersPerUser.get(order.Uid);
          if (orderArray) {
            orderArray.push(order.Id);
          } else {
            allOrdersPerUser.set(order.Uid, [order.Id]);
          }
        });

        return allOrdersPerUser;
      } catch (error) {
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
        // return empty map for Promise
        return new Map<string, number[]>();
      }
    },
    []
  );

  /**
   * Helper function to call OrdersHavingArticles from API
   */
  const getOrdersWithArticlesByOrderId = useCallback(
    async (
      orderId: number,
      authHeaderConfig?: AxiosRequestConfig<any>
    ): Promise<OrderHavingArticles[]> => {
      let matchingOrderedArticles: OrderHavingArticles[] = [];
      try {
        if (authHeaderConfig) {
          matchingOrderedArticles = await OrdersHavingArticles.getAllByOrderId(
            orderId,
            authHeaderConfig
          );
        } else {
          matchingOrderedArticles = await OrdersHavingArticles.getAllByOrderId(
            orderId
          );
        }

        return matchingOrderedArticles;
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
        return [];
      }
    },
    []
  );

  /**
   * Async function that retrieves by allOrdersUserMap
   * for each order the belonging articles from OrdersHavingArticles
   * and maps them to display object OrdersPerUser[]
   */
  const mapOrdersWithArticles = useCallback(
    async (
      allOrdersPerUserMap: Map<string, number[]>,
      authHeaderConfig?: AxiosRequestConfig<any>
    ) => {
      try {
        let key: string = '';
        const ordersPerUser: OrdersPerUser[] = [];
        let ordersHavingArticles: OrderHavingArticles[] = [];

        await Promise.all(
          Array.from(allOrdersPerUserMap.entries()).map(
            async ([uid, orderIds]) => {
              key = uid;
              ordersHavingArticles = [];

              // map over all orderIds and get orders that match uid
              await Promise.all(
                orderIds.map(async (orderId) => {
                  // get all objects that match the orderId
                  let matchingOrderedArticles =
                    await getOrdersWithArticlesByOrderId(
                      orderId,
                      authHeaderConfig
                    );
                  // push each
                  matchingOrderedArticles.map(async (order) => {
                    ordersHavingArticles.push(order);
                  });
                })
              );

              // object definition orderPerUser
              const orderPerUser: OrdersPerUser = {
                Uid: key,
                orders: ordersHavingArticles,
              };

              ordersPerUser.push(orderPerUser);
            }
          )
        );

        setOrdersPerUser(ordersPerUser);
      } catch (error) {
        // TODO: add proper error handling
        console.log('Error message:' + error);
        setIsUserOrdersContextLoading(false);
      }
    },
    [getOrdersWithArticlesByOrderId]
  );

  /**
   * Async wrapper function to get all user orders data
   */
  const fetchAllOrdersData = useCallback(
    async (authHeaderConfig?: AxiosRequestConfig) => {
      let allOrdersPerUser: Map<string, number[]>;
      if (authHeaderConfig) {
        allOrdersPerUser = await getAllOrdersPerUser(authHeaderConfig);
        await mapOrdersWithArticles(allOrdersPerUser, authHeaderConfig);
      } else {
        allOrdersPerUser = await getAllOrdersPerUser();
        await mapOrdersWithArticles(allOrdersPerUser);
      }
    },
    [getAllOrdersPerUser, mapOrdersWithArticles]
  );

  useEffect(() => {
    if (authorizationRequestHeaderConfig.headers?.Authorization !== undefined) {
      // fix for react with ignore to not render the useEffect twice in development mode
      // see docs: https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
      if (ignore_fetchData.current === false) {
        setIsUserOrdersContextLoading(true);
        fetchAllOrdersData(authorizationRequestHeaderConfig);
        setIsUserOrdersContextLoading(false);
      }

      return () => {
        ignore_fetchData.current = true;
      };
    }
  }, [
    mapOrdersWithArticles,
    fetchAllOrdersData,
    authorizationRequestHeaderConfig,
    authorizationRequestHeaderConfig.headers?.Authorization,
  ]);

  if (isAllOrdersContextLoading) {
    //TODO: add a text value for missing data
    return <></>;
  }

  const allOrdersContextValue: AllOrdersContextType = {
    ordersPerUser,
    isAllOrdersContextLoading,
    setOrdersPerUser,
  };

  return (
    <AllOrdersContext.Provider value={allOrdersContextValue}>
      {children}
    </AllOrdersContext.Provider>
  );
};

export default AllOrdersContextProvider;
